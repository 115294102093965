<template>
  <UiBaseHeaderContainer :navigation="$route.meta?.nav" />
  <div class="layout-wrapper">
    <component :is="layoutComponent">
      <RouterView />
    </component>
  </div>
  <Transition name="fade">
    <UiBaseAppLoader v-if="isLoading" />
  </Transition>
  <UiBaseToastContainer />
  <StopImpersonateButton />
  <UiBaseModalContainer />
</template>

<script setup lang="ts">
import * as feedback from '@feedback';
import * as impersonate from '@impersonate';
import * as warehouseAddress from '@warehouse-address';
import * as analitics from '@analitics';
import * as request from '@request';
import * as register from '@register-unauthorize';
import * as registrationApplication from '@registration-application';
import * as baseUi from '@ui-base';
import * as globalUi from '@ui-global';
import * as configurationImport from '@import-configuration';
import * as invoiceDetails from '@invoice-details';
import * as invoiceList from '@invoice-list';
import * as orderList from '@order-list';
import * as supplierList from '@supplier-list';
import * as userList from '@user-list';
import * as shipmentList from '@shipment-list';
import * as communicationPanel from '@communication-panel';
import * as userCommon from '@user-common';

import DefaultLayout from './layouts/DefaultLayout.vue';

const {
  UiBaseModalContainer,
  UiBaseToastContainer,
  UiBaseHeaderContainer,
  UiBaseAppLoader,
  useLoader,
} = baseUi;
const { StopImpersonateButton } = impersonate.components;

const $route = useRoute();
const { isLoading } = useLoader();

const layoutComponent = computed(() => {
  if ($route.meta?.layout) {
    return $route.meta.layout;
  }

  return DefaultLayout;
});

const { appContext } = getCurrentInstance();

// Initialize all packages after app build
feedback.initPackage();
impersonate.initPackage();
warehouseAddress.initPackage();
analitics.initPackage();
request.initPackage();
register.initPackage();
registrationApplication.initPackage();
globalUi.initPackage(appContext.app);
configurationImport.initPackage();
invoiceDetails.initPackage();
invoiceList.initPackage();
orderList.initPackage();
supplierList.initPackage();
userCommon.initPackage();
userList.initPackage();
shipmentList.initPackage();
communicationPanel.initPackage();
</script>

<style lang="scss" scoped>
.layout-wrapper {
  width: 100%;
  min-height: calc(100vh - $s-header);
  overflow-x: hidden;
}
</style>
