import type { CARRIERS, COUNTRY } from '@enums/global.enums';
import type { SUPPLIER_STATUS, SUPPLIER_TYPES } from '@supplier-common';
import type { SHIPPING_METHOD } from '@order-common';

export type SuppliersDataType = 'payment' | 'shipping' | 'edi_configuration' | 'contact_book' | 'address_book'

// https://tim-sa.atlassian.net/wiki/spaces/TMD1TECH/pages/6979692/Terminy+p+atno+ci+PD+i+SRM
export const enum PAYMENT_DATE {
  DAYS_3 = 'DAYS_3',
  DAYS_7 = 'DAYS_7',
  DAYS_14 = 'DAYS_14',
  DAYS_21 = 'DAYS_21',
  DAYS_30 = 'DAYS_30',
  DAYS_40 = 'DAYS_40',
  DAYS_45 = 'DAYS_45',
  DAYS_55 = 'DAYS_55',
  DAYS_58 = 'DAYS_58',
  DAYS_60 = 'DAYS_60',
  DAYS_65 = 'DAYS_65',
  DAYS_70 = 'DAYS_70',
  DAYS_75 = 'DAYS_75',
  DAYS_88 = 'DAYS_88',
  DAYS_90 = 'DAYS_90',
  DAYS_120 = 'DAYS_120',
  DAYS_345 = 'DAYS_345',
  DAYS_700 = 'DAYS_700',
  
  // Termin - kompensata w dniach
  KO_1 = 'KO_1',
  KO_14 = 'KO_14',
  KO_30 = 'KO_30',
  KO_45 = 'KO_45',
  KO_60 = 'KO_60',
  KO_90 = 'KO_90',
  
  // Inne
  PBD = 'PBD', // Pobranie - dostawca
  PAYU = 'PayU',
  ALLFIN = 'AllegroFinanse',
  DOTPAY = 'DotPayPGE',
  CASH = 'Gotówka',
  CARD = 'Karta',
  PRAGMA = 'PragmaGo Raty',
  CASHONDEL = 'Pobranie',
  PREPAY = 'Przedpłata',
  P24 = 'przelewy24.pl',
  DEPOSIT = 'Zadatek',
}

export interface Payment {
  paymentDate: PAYMENT_DATE,
  accountNumber: string,
}

export interface Shipping {
  shippingMethod: SHIPPING_METHOD | string,
  carrier: CARRIERS,
  customCarrierName: string,
  shippingPriceList: {
    packagePrice: number
    packageFreeShippingThreshold: number
    palettePrice: number
    paletteFreeShippingThreshold: number
    overSizePrice: number | null
    overSizePriceFreeShippingThreshold: number | null
  }
}

interface Address {
  buildingNumber?: string,
  street: string
  city: string
  postCode: string
  country: COUNTRY,
}

export interface AddressBook {
  mainAddress: Address
  complaintAndReturnAddress?: Address
}

export interface EdiConfiguration {
  enabled: boolean
  glnOrIln: string
}

export interface SupplierContactPerson {
  id: string
  email: string
  phone: string
  firstName: string
  lastName: string
  fullName: string
}

export interface ContactBook {
  sell: SupplierContactPerson // it should be first in object
  offer: SupplierContactPerson
  documents: SupplierContactPerson
  complaints: SupplierContactPerson
  technical: SupplierContactPerson
  edi: SupplierContactPerson
  order: SupplierContactPerson
}

export interface Supplier {
  id: string
  mfgid: string
  taxId: string
  name: string
  companySize: string // TODO: change to enum
  fullName: string
  mainFirstName: string
  mainLastName: string
  mainEmail: string
  mainPhone: string
  description: string
  status: SUPPLIER_STATUS
  type: SUPPLIER_TYPES
  owzDate: string
}

// BE
export type SupplierListItemOutput = {
  mfgid: string
  taxId: string
  name?: string
  status: SUPPLIER_STATUS
  type?: SUPPLIER_TYPES
  id: string
  applicationDate?: string
  owzDate?: string
  source?: string
}

// FE
export type SupplierListItem = {
  id: string
  mfgid: string
  taxId: string
  name: string
  type?: SUPPLIER_TYPES
  status: SUPPLIER_STATUS
  applicationDate: string
  owzDate: string
  source: string
}
