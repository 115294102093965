import baseService, { toFormData } from '@services/baseService';
import { useRequest } from '@request';
import { mapMessageCountMap, mapSegmentList } from '../mappers/common';
import type { SegmentItemDTO } from '../model/SegmentItemDTO';
import type { MessageCountMapDTO } from '../model/MessageCountMapDTO';
import type { SegmentList } from '../model/Segment';
import type { MessageCountMap } from '../model/MessageCount';
import type { ThreadRequireUserAction } from '../model/Thread';
import type { DeleteMessageFromThreadPayload, EditMessageInThreadPayload } from '../model/Payload';

export const getSegmentList = async (): Promise<SegmentList> => {
  const { callRequest } = useRequest();

  const { data } =  await callRequest<
  SegmentItemDTO[],
  SegmentList
  >(
    baseService.get<{
      id: string,
      name: string,
    }[]>(`/segments`),
    {
      transformData: (list) => mapSegmentList(list),
      errorFallbackData: () => mapSegmentList([]),
    },
  );

  return data;
};

export const getMessageCountList = async (): Promise<{ messageCountList: MessageCountMap, isUnread: ThreadRequireUserAction }> => {
  const { callRequest } = useRequest();

  const { data } =  await callRequest(
    baseService.get<MessageCountMapDTO>(`/thread/count`),
    {
      transformData: (messageCountList) => ({
        messageCountList: mapMessageCountMap(messageCountList),
        isUnread: !!messageCountList.isUnread as ThreadRequireUserAction,
      }),
      errorFallbackData: () => ({
        messageCountList: mapMessageCountMap({
          active: 0,
          deleted: 0,
          all: 0,
          finished: 0,
        }),
        isUnread: false as ThreadRequireUserAction,
      }),
    },
  );

  return data;
};

export const getUnreadThreadsCount = async (): Promise<{ data: number }> => {
  const { callRequest } = useRequest();

  return await callRequest<{ user: { unreadThreadsCount: number } }, number>(
    baseService.get('/users/reload'),
    {
      transformData: (data) => data.user.unreadThreadsCount,
      errorFallbackData: () => 0,
    },
  );
};

export const deleteMessageFromThread = async (payload: DeleteMessageFromThreadPayload): Promise<{ hasError: boolean }> => {
  const { callRequest } = useRequest();

  return callRequest(baseService.delete(`/messages/${payload.id}`));
};

export const editMessageInThread = async (payload: EditMessageInThreadPayload): Promise<{ hasError: boolean }> => {
  const { callRequest } = useRequest();

  const formData = toFormData({ content: payload.content });
  payload.files.forEach((file) => {
    formData.append('files[]', file);
  });

  const { hasError } = await callRequest(
    baseService.post(`/messages/${payload.id}/update`, formData),
    {
      successMessage: 'Wiadomość została zaktualizowana',
    },
  );

  return { hasError };
};
