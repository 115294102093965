<template>
  <div
    class="thread-preview"
    :class="[className, withSeparator && 'with-separator']"
  >
    <LineDecorator>
      <RequireUserActionDot
        class="number"
        data-t="thread-preview-number"
        :require-user-action="requireUserAction"
        :text="number"
      />
      <div
        class="created-at"
        :class="{ 'is-bold': requireUserAction }"
        data-t="thread-preview-createdAt"
      >
        {{ createdAt }}
      </div>
    </LineDecorator>
    <LineDecorator>
      <div
        class="segment"
        :title="segment.name"
        data-t="thread-preview-segment"
      >
        {{ segment.name }}
      </div>
      <ThreadStatus :status="status" />
    </LineDecorator>
    <div
      class="title"
      :title="title"
      data-t="thread-preview-title"
    >
      {{ title }}
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SupplierThreadItem } from '../../model/Thread';
import { THREAD_STATUS } from '../../model/Thread';
import LineDecorator from '../decorators/LineDecorator.vue';
import RequireUserActionDot from '../common/RequireUserActionDot.vue';
import ThreadStatus from '../common/ThreadStatus.vue';

type Props = SupplierThreadItem & {
  withSeparator?: boolean
}
const props = defineProps<Props>();

const className = computed(() => {
  switch (props.status) {
    case THREAD_STATUS.ACTIVE:
      return 'is-active';
    case THREAD_STATUS.FINISHED:
      return 'is-finished';
    case THREAD_STATUS.DELETED:
      return 'is-deleted';
    default:
      return '';
  }
});
</script>

<style lang="scss" scoped>
$padding: 12px;

.thread-preview {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4px;
  padding: $padding;
  transition: $base-transition;
  cursor: pointer;

  &.is-active {
    background: $c-tim-gray-lightest-alt;
  }

  &.is-finished,
  &.is-deleted {
    background: $c-tim-gray-lightest;
  }

  &.with-separator {
    position: relative;

    @include line-bottom($padding);
  }

  &:hover {
    background: $c-tim-blue-lightest;
  }
}

.number,
.segment {
  @include t7;
}

.segment {
  @include one-line-text-with-dots;
}

.created-at {
  @include t9;

  &.is-bold {
    @include t9-bold;
  }
}

.title {
  @include t9;
  @include one-line-text-with-dots;

  color: $c-tim-gray;
}
</style>
