import { ROUTER_LINKS } from '@enums/global.enums';
import type { RouteRecordRaw } from 'vue-router';

export const createSupplierScoringRoute = (): RouteRecordRaw => ({
  path: ROUTER_LINKS.AFTER_SALES,
  name: 'AppAfterSales',
  redirect: ROUTER_LINKS.AFTER_SALES_SCORING,
  component: () => import('@pages/EmptyRouterView.vue'),
  meta: {
    pageTitle: 'Obsługa posprzedażowa',
  },
  children: [
    {
      path: ROUTER_LINKS.AFTER_SALES_SCORING,
      name: 'AppAfterSalesScoring',
      component: () => import('./components/PageView.vue'),
      meta: {
        pageTitle: 'Ocena i jakość obsługi',
      },
    },
  ],
});
