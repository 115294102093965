<template>
  <div class="message-box-edit-mode">
    <div class="header">
      <GlobalButton
        as-text
        class="button button--file"
        title="Dodaj plik"
        :disabled="isLoading"
        @click.stop="openFileDialog"
      >
        <GlobalIcon
          name="attach_file_add"
          is-symbol
          class="button__icon"
        />
      </GlobalButton>
      <GlobalButton
        as-text
        class="button"
        title="Zapisz wiadomość"
        :disabled="isLoading"
        @click.stop="handleSaveEdit"
      >
        <GlobalIcon
          name="save"
          is-symbol
          class="button__icon"
        />
      </GlobalButton>
      <GlobalButton
        as-text
        class="button button--decline"
        title="Odrzuć wiadomość"
        :disabled="isLoading"
        @click.stop="$emit('decline-edit')"
      >
        <GlobalIcon
          name="close"
          is-symbol
          class="button__icon"
        />
      </GlobalButton>
    </div>
    <div class="content">
      <div
        v-if="isLoading"
        class="content__loader"
        aria-label="Ładowanie wiadomości"
      >
        <GlobalLoader size="24px" />
      </div>
      <FileListDecorator
        v-if="savedFileList.length || newFileList.length"
        :columns="2"
      >
        <FileDisplay
          v-for="(file, index) in savedFileList"
          :key="index"
          :file="file"
          aria-label="Załączony poprzednio plik"
        />
        <FileDisplayNew
          v-for="(file, index) in newFileList"
          :key="index"
          :file="file"
          aria-label="Załączony nowy plik"
          @delete="deleteFile(index)"
        />
      </FileListDecorator>
      <textarea
        ref="textarea"
        v-model="input"
        class="content__text"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { MessageItem } from '../../model/MessageItem';
import _cloneDeep from 'lodash/cloneDeep';
import FileDisplayNew from './file/FileDisplayNew.vue';
import { useFileList } from '../../composables/common/useFileList';
import FileListDecorator from '../decorators/FileListDecorator.vue';
import { useFileDownload } from '@ui-base';
import type { FileItem } from '../../model/File';
import FileDisplay from './file/FileDisplay.vue';
import { useFocus, useObjectUrl, useTextareaAutosize } from '@vueuse/core';

type Props = Pick<MessageItem, 'fileList' | 'isSending' | 'content'>;
const props = defineProps<Props>();

const $emit = defineEmits<{
  (event: 'save-edit', payload: { content: string, files: File[] }): void
  (event: 'decline-edit'): void
}>();

const savedFileList = ref<FileItem[]>([]);
// const deleteSavedFile = (index: number) => {
//   savedFileList.value = savedFileList.value.filter((_, i) => i !== index);
// };

const isLoading = computed(() => props.isSending);
const {
  fileList: newFileList,
  deleteFile,
  openFileDialog,
} = useFileList({ isDisabled: isLoading });
const { fetchFile } = useFileDownload();

const { textarea, input } = useTextareaAutosize();
useFocus(textarea, { initialValue: true });
input.value = props.content;

onMounted(async () => {
  savedFileList.value = await Promise.allSettled(_cloneDeep(props.fileList)
    .sort((a, b) => a.type.localeCompare(b.type))
    .filter((file) => file.link)
    .map(async (file) => {
      const blobFile = await fetchFile({
        link: file.link,
        name: file.name,
        format: file.type,
        method: 'get',
      });

      return {
        ...file,
        link: useObjectUrl(blobFile).value as FileItem['link'],
      };
    }),
  ).then((result) => {
    return result
      .filter((file) => file.status === 'fulfilled')
      .map((file) => file.value);
  });
});

const handleSaveEdit = async () => {
  const files = [...newFileList.value];
  $emit('save-edit', {
    content: input.value || '',
    files,
  });
};
</script>

<style lang="scss" scoped>
.message-box-edit-mode {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  background-color: $c-white;
  border: 1px solid $c-tim-gray;
  border-radius: $base-radius;
  transition: $base-transition;
  position: relative;

  &:has(.content__text:focus-visible) {
    outline: none;
    border: 2px solid $c-tim-blue;
  }

  &__text {
    width: 100%;
    white-space: pre-wrap;
    padding: none;
    border: none;
    resize: none;

    &:focus-visible {
      outline: none;
    }
  }

  &__loader {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($c-white, 0.8);
    z-index: 1;
    border-radius: $base-radius;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.button {
  height: 26px;
  width: 26px;
  transition: $base-transition;

  &__icon {
    font-size: 16px;
  }
}

.button--decline {
  color: $c-tim-red-alt;

  &:hover {
    color: $c-tim-red;
  }
}

.button--file {
  color: $c-tim-gray-dark;

  &:hover {
    color: $c-black;
  }
}
</style>
