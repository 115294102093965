<template>
  <div class="mobile-element">
    <GlobalLink
      v-if="!navigationElement.subitems"
      class="mobile-element__link"
      :to="navigationElement.to"
    >
      {{ navigationElement.text }}
    </GlobalLink>
    <span
      v-else
      class="mobile-element__link"
      @click="isSubmenuOpen = !isSubmenuOpen"
    >
      {{ navigationElement.text }}
      <GlobalIcon
        name="expand_more"
        class="submenu-icon"
        :class="{ 'submenu-icon--active': isSubmenuOpen }"
      />
    </span>

    <ul
      v-if="isSubmenuOpen"
      class="mobile-element__submenu"
    >
      <li
        v-for="subitem in navigationElement.subitems"
        :key="subitem.to"
      >
        <GlobalLink
          v-if="subitem.to"
          class="mobile-element__menu-link"
          :to="subitem.to"
        >
          {{ subitem.text }}
        </GlobalLink>
        <div
          v-else
          class="mobile-element__menu-link"
          v-on="subitem.on"
        >
          {{ subitem.text }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import type { NavigationElement } from '../model';

interface Props {
  navigationElement: NavigationElement
}
defineProps<Props>();
const isSubmenuOpen = ref(false);
</script>

<style lang="scss" scoped>

.mobile-element {
  padding: 8px 0;

  &__link {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    @include t7;
  }

  .router-link-active {
    color: $c-tim-accent;
  }

  &__menu-link {
    padding: 8px 0;
    width: 100%;
    display: flex;

    @include t10;
  }

  &__submenu {
    padding: 8px 0 0 10px;
  }

  .submenu-icon {
    transition: transform $base-transition;

    &--active {
      transform: rotate(-180deg);
    }
  }
}
</style>
