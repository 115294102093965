import type { AxiosResponse } from 'axios';
import baseService from './baseService';
import type { Supplier, SuppliersDataType, SupplierContactPerson, SupplierListItemOutput } from '../types/Supplier.types';
import type { SUPPLIER_STATUS } from '@supplier-common';
import { useRequest } from '@request';
import type { TableServiceRequestResponse } from '@ui-table';

interface ContactPersonInput {
  email: string
  phone: string
  firstName: string
  lastName: string
  supplier: string
}

export const getSuppliersData = async (query = ''): Promise<TableServiceRequestResponse<SupplierListItemOutput>> => {
  const { callRequest } = useRequest();

  return callRequest(baseService.get(`/suppliers${query}`, { headers: { 'Accept': 'application/ld+json' } }));
};

export const getSupplier = async (id: string): Promise<AxiosResponse<Supplier>> => {
  return await baseService.get(`/suppliers/${id}`);
};

// TODO: refaktor typów i podziału admin/supplier
export const updateSupplier = async (id: string, payload: Partial<{
  mfgid: string
  taxId: string
  name: string
  status: SUPPLIER_STATUS
  description: string,
  companySize: string,
  mainFirstName: string,
  mainLastName: string,
  mainEmail: string,
  mainPhone: string,
}>): Promise<AxiosResponse<Supplier>> => {
  return await baseService.patch(`/suppliers/${id}`, payload);
};

export const getSupplierData = async <T>(id: string, dataType: SuppliersDataType): Promise<AxiosResponse<T>> => {
  return await baseService.get(`/suppliers/${id}/${dataType}`);
};

export const patchSupplierData = async <T>(id: string, dataType: SuppliersDataType, payload: unknown): Promise<AxiosResponse<T>> => {
  return await baseService.patch(`/suppliers/${id}/${dataType}`, payload);
};

export const getSupplierContactPersons = async (query = '', id = ''): Promise<SupplierContactPerson[]> => {
  const { callRequest } = useRequest();

  const { data } = await callRequest(
    baseService.get(`/suppliers/${id}/contact_person_collections?s=${query}`),
  );

  return data;
};

export const postContactPerson = async (data: ContactPersonInput): Promise<AxiosResponse<SupplierContactPerson>> => {
  return await baseService.post('/supplier_contact_people', data, { headers: { 'Content-Type': 'application/ld+json' } });
};

export const patchContactPerson = async (id: string, data: Partial<ContactPersonInput>): Promise<AxiosResponse<SupplierContactPerson>> => {
  return await baseService.patch(`/supplier_contact_people/${id}`, data);
};

export const deleteContactPerson = async (id: string): Promise<AxiosResponse> => {
  return await baseService.delete(`/supplier_contact_people/${id}`);
};

// TODO: refaktor przy rozdziwlaniu supplier od admina
type SupplierOwz = {
  accepted: string
  date: string
}

export const getSupplierOwzStatute = async (id: string): Promise<AxiosResponse<SupplierOwz>> => {
  return await baseService.get(`/suppliers/${id}/accept_owz_statute`, { headers: { 'Content-Type': 'application/json' } });
};

export const acceptSupplierOwzStatute = async (id: string): Promise<AxiosResponse<SupplierOwz>> => {
  return await baseService.post(`/suppliers/${id}/accept_owz_statute`, {}, { headers: { 'Content-Type': 'application/json' } });
};
