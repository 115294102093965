<template>
  <button
    class="base-btn"
    :class="buttonClasses"
    :disabled="isButtonDisabled"
  >
    <slot name="icon-prepend">
      <GlobalIcon
        v-if="icon"
        :name="icon"
        class="icon-prepend"
      />
    </slot>
    <slot />
    <GlobalLoader
      v-if="loading"
      class="icon-loading"
      size="24px"
      buffer
    />
  </button>
</template>

<script lang="ts" setup>
interface Props {
  outlined?: boolean
  fullWidth?: boolean
  loading?: boolean
  disabled?: boolean
  dense?: boolean,
  icon?: string,
  noBorder?: boolean,
  asText?: boolean,
  decoration?: string
}

const props = withDefaults(defineProps<Props>(), {
  outlined: false,
  fullWidth: false,
  loading: false,
  disabled: false,
  dense: false,
  icon: '',
  noBorder: false,
  decoration: 'uppercase',
});

const buttonClasses = computed(() => {
  return {
    'base-btn--outlined': props.outlined,
    'base-btn--no-border': props.noBorder,
    'base-btn--full-width': props.fullWidth,
    'base-btn--disabled': isButtonDisabled.value,
    'base-btn--dense': props.dense,
    'base-btn--as-text': props.asText,
  };
});
const isButtonDisabled = computed(() => {
  return props.disabled || props.loading;
});
</script>

<style lang="scss" scoped>
.base-btn {
  transition: background-color $base-transition;
  border: none;
  height: 48px;
  padding: 12px 24px;
  background-color: $c-tim-blue;
  color: $c-white;
  text-transform: v-bind("props.decoration");
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  user-select: none;
  border-radius: $base-radius;
  letter-spacing: 1.25px;

  @include to-sm {
    font-size: 13px;
    padding: 16px 8px;
  }

  &:hover {
    background-color: $c-tim-blue-dark;
  }

  &--dense {
    height: 36px;
  }

  &--full-width {
    width: 100%;
  }

  &--disabled {
    background-color: $c-tim-gray;
    cursor: not-allowed;

    &:hover {
      background-color: $c-tim-gray;
    }
  }

  &--outlined {
    background-color: $c-tim-blue-light;
    color: $c-tim-blue;
    border: 1px solid $c-tim-blue;

    &:hover {
      background-color: $c-tim-blue-lightest;
    }

    &.base-btn--disabled {
      background-color: $c-tim-blue-light;
    }
  }

  &--no-border {
    border: none;
  }

  &--as-text {
    background-color: transparent;
    color: $c-tim-blue;
    border: none;
    padding: 0;
    height: 24px;
    transition: color $base-transition;

    &:hover {
      background-color: transparent;
      color: $c-tim-blue-dark;
    }

    &.base-btn--disabled {
      background-color: transparent;
      color: $c-tim-gray;
    }
  }

  .icon-prepend {
    margin-right: 9px;
  }

  .icon-loading {
    margin-left: 16px;
  }
}
</style>
