<template>
  <div
    v-if="isImpersonating"
    class="stop-impersonate-button"
  >
    {{ $t('impersonate.info') }}
    <GlobalButton
      class="stop-impersonate-button__cta"
      :dense="true"
      @click="stopImpersonate"
    >
      {{ $t('impersonate.stop') }}
    </GlobalButton>
  </div>
</template>

<script setup lang="ts">
import useImpersonateSession from '../composables/useImpersonateSession';

const { stopImpersonate, isImpersonating } = useImpersonateSession();
</script>

<style lang="scss" scoped>
.stop-impersonate-button {
  position: fixed;
  bottom: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 4px 8px;
  background: $c-tim-red-light;
  border-top: 1px solid $c-tim-red;
  font-weight: 700;

  &__cta {
    margin-left: 4px;
  }
}
</style>
