<template>
  <div class="container">
    <div
      class="textarea-element"
      :class="inputContainerClasses"
    >
      <label
        v-if="label"
        data-t="textarea-element-label"
        class="label"
        :for="String(id)"
      >
        {{ label }}
        {{ isRequired ? '*' : '' }}
      </label>
      <slot name="above-input" />
      <div
        class="field-wrapper"
        :style="normalizedStyles"
      >
        <div class="field-wrapper--overflow">
          <Field
            :id="id"
            ref="textarea"
            v-model="input"
            as="textarea"
            class="input"
            type="text"
            validate-on-blur
            :name="name"
            :disabled="isDisabled"
            :rules="normalizedRules"
            :placeholder="placeholder"
            :maxlength="maxLength"
            :rows="rows"
            @blur="handleBlur"
            @focus="handleFocus"
          />
        </div>
        <slot name="after-input" />
      </div>
      <span
        v-if="isMaxLength"
        data-t="textarea-element-length"
        class="max-length-count"
      >
        {{ `${input.length}/${maxLength}` }}
      </span>
    </div>
    <div
      v-if="errorMessage"
      class="error-message"
      data-t="textarea-element-error"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { onKeyStroke, useMagicKeys, useTextareaAutosize, useVModel } from '@vueuse/core';
import { Field } from 'vee-validate';
import { useFormItemState } from '../composables/useFormItemState';
import { safeJoin } from '@helpers/functions';
import type { FormElementProps } from '../model';

type Props = FormElementProps & {
  maxHeight?: number,
  maxLength?: number | string,
  rows?: number | string,
  preventEnterSubmit?: boolean,
}
const props = withDefaults(defineProps<Props>(), {
  placeholder: '',
  maxHeight: 0,
  label: '',
  rules: '',
  isDisabled: false,
  maxLength: undefined,
  rows: 1,
  preventEnterSubmit: true,
});
const $emit = defineEmits<{
  (event: 'update:modelValue', payload: string): void
  (event: 'submit'): void
}>();
const input = useVModel(props, 'modelValue', $emit);

// autoresize based on content
const textarea = useTemplateRef('textarea');
const textareaElement = ref<HTMLTextAreaElement>();
useTextareaAutosize({
  styleProp: 'minHeight',
  element: textareaElement,
  input,
});
onMounted(() => {
  textareaElement.value = textarea.value.$el;
});

// key listeners
const { shift } = useMagicKeys({ target: textareaElement });
onKeyStroke('Enter', (e) => {
  if (props.preventEnterSubmit) {
    return;
  }

  // allow "shift + enter" to create new line
  if (shift.value) {
    return;
  }

  $emit('submit');
  // prevent all other cases with "enter"
  e.preventDefault();
}, { target: textareaElement });

const {
  handleBlur,
  handleFocus,
  errorMessage,
  inputContainerClasses,
  isRequired,
  placeholder,
} = useFormItemState(input, props);

// rules
const isMaxLength = computed(() => Number(props.maxLength) > 0);
const normalizedRules = computed(() => safeJoin([props.rules, isMaxLength.value && `maxLength:${props.maxLength}`], '|'));

// decorator
const normalizedStyles = computed(() => {
  return {
    maxHeight: props.maxHeight ? `${props.maxHeight}px` : 'initial',
  };
});
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
}

.textarea-element {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: border-color $base-transition;
  border: 1px solid $c-tim-gray-light;
  border-radius: $base-radius;
  background-color: $c-white;
  padding: 7px 8px;
  position: relative;

  &.is-active,
  &.is-focused {
    border-color: $c-tim-blue;

    .label {
      @include t9;

      line-height: 24px;
      top: -13px;
      padding: 0 2px;
      color: $c-tim-blue;

      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &.is-invalid {
    border-color: $c-tim-red;

    .label {
      color: $c-tim-red;
    }

    .input {
      color: $c-tim-red;
    }
  }

  &.is-disabled {
    border-color: $c-tim-gray-light;
    cursor: default;

    .input,
    .label {
      color: $c-tim-gray;
      cursor: default;
    }
  }
}

.input {
  @include base-font;

  background: transparent;
  resize: none;
  outline: none;
  border: none;
  width: 100%;
  flex-grow: 1;
  margin: 4px 0;
  padding: 0;
}

.label {
  @include base-font;

  transition: all $base-transition;
  position: absolute;
  left: 16px;
  top: 11px;
  color: $c-tim-gray-dark;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  z-index: 0;
  pointer-events: none;
  max-width: 100%;

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 2.5px;
    background-color: $c-white;
    bottom: 11px;
    z-index: -1;
    left: 0;
    opacity: 0;
    visibility: hidden;
  }
}

.field-wrapper {
  display: flex;
  width: 100%;

  &--overflow {
    display: flex;
    width: 100%;
    overflow: auto;
  }
}

.error-message {
  @include t9;

  color: $c-tim-red;
  line-height: 24px;
  min-height: 18px;
  padding: 2px 0 0 12px;
}

.max-length-count {
  @include t9;

  position: absolute;
  top: 100%;
  left: 8px;
  transform: translateY(-50%);
  color: $c-tim-gray-dark;

  &::before {
    content: "";
    width: 100%;
    height: 2.5px;
    background-color: $c-white;
    position: absolute;
    top: 7px;
    left: 0;
    z-index: -1;
  }
}
</style>
