import type { AxiosResponse } from 'axios';
import baseService from './baseService';
import { useRequest } from '@request';

interface PimcoreLoginResponse {
  pimCoreUrlKey: string
}

interface PimcoreSessionVerificationResponse {
  isValid: boolean
  isNew: boolean
}

type PimcoreSessionInfo = {
  pimcoreAvailable?: boolean,
  isPimcoreUser?: boolean
  pimCoreToken?: string
}
export const reloadPimcoreSession = async (): Promise<PimcoreSessionInfo> => {
  const { callRequest } = useRequest();

  const { data } = await callRequest(
    baseService.get<PimcoreSessionInfo>('/users/reload/pimcore'),
    {
      errorFallbackData: () => ({}),
    },
  );

  return data;
};

export const loginPimcore = async (email: string): Promise<void> => {
  const { callRequest } = useRequest();

  const pimcoreData = await reloadPimcoreSession();
  if (!pimcoreData.pimcoreAvailable || !pimcoreData.isPimcoreUser) {
    return;
  }

  await callRequest(baseService.post(
    `${import.meta.env.VITE_PIMCORE_URL}/api/authenticate-user-by-token`,
    { email, token: pimcoreData.pimCoreToken },
  ), { autoSuccessMessage: false });
};

export const verifyPimcoreSession = async (): Promise<AxiosResponse<PimcoreSessionVerificationResponse>> => {
  return await baseService.post(`${import.meta.env.VITE_PIMCORE_URL}/api/verify-session`);
};

export const loginPimcoreByAuthUrlKey = async (email: string, password: string): Promise<AxiosResponse<PimcoreLoginResponse>> => {
  return await baseService.post('/super-pim/authenticate-user-for-redirect', { email, password });
};
