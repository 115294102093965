<template>
  <div class="container">
    <div
      class="text-input"
      data-t="text-input"
      :class="classNames"
    >
      <label
        v-if="label"
        data-t="text-input-label"
        class="label"
        :for="String(id)"
      >
        {{ label }}
        {{ isRequired ? '*' : '' }}
      </label>
      <slot name="above-input" />
      <div class="field-wrapper">
        <slot name="before-input" />
        <Field
          :id="id"
          ref="textInput"
          v-model="input"
          class="input"
          :type="type"
          validate-on-blur
          :name="name"
          :disabled="isDisabled"
          :rules="rules"
          :placeholder="placeholder"
          :autocomplete="autocomplete"
          @blur="handleBlurWithEmit"
          @focus="handleFocus"
        />
        <slot name="after-input" />
      </div>
    </div>
    <div
      v-if="errorMessage || $slots['error-message']"
      class="error-message"
      data-t="text-input-error"
    >
      <slot name="error-message">
        {{ errorMessage }}
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import { Field } from 'vee-validate';
import { useFormItemState } from '../composables/useFormItemState';
import type { TextInputProps } from '../model';

type Props = TextInputProps;
const props = withDefaults(defineProps<Props>(), {
  type: 'text',
  placeholder: '',
  label: '',
  rules: '',
  isDisabled: false,
  isReadOnly: false,
  isDense: false,
  autocomplete: 'off',
  backgroundColor: 'white',
});
const $emit = defineEmits<{
  (event: 'update:modelValue', payload: string): void
  (event: 'blur'): void
}>();
const input = useVModel(props, 'modelValue', $emit);

const {
  handleBlur,
  handleFocus,
  errorMessage,
  inputContainerClasses,
  isRequired,
  isFocused,
  placeholder,
} = useFormItemState(input, props);

const handleBlurWithEmit = () => {
  handleBlur();
  $emit('blur');
};

const classNames = computed(() => ({
  ...inputContainerClasses.value,
  'is-dense': props.isDense,
  'is-read-only': props.isReadOnly,
  [`is-color-${props.backgroundColor}`]: true,
}));

// EDGE CASE: when user uses autocomplete then set value in component so it will get focused state
const textInput = useTemplateRef('textInput');
const intervalRef = setInterval(() => {
  if (props.autocomplete !== 'on' || isFocused.value) {
    return clearInterval(intervalRef);
  }

  if (!textInput.value?.$el.value) {
    return;
  }

  input.value = textInput.value?.$el.value;
  clearInterval(intervalRef);
}, 1000);
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.text-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: border-color $base-transition;
  border: 1px solid $c-tim-gray-light;
  border-radius: $base-radius;
  padding: 7px 16px;
  position: relative;
  
  &.is-color-white {
    background-color: $c-white;
  }
  
  &.is-color-gray {
    background-color: $c-tim-gray-lightest-alt;
  }

  &.is-read-only {
    pointer-events: none;
  }

  &.is-dense {
    padding: 7px 8px;
    
    .input {
      font-size: 12px;
      min-height: 24px;
    }

    .label {
      top: 7px;
    }
  }

  &.is-active,
  &.is-focused {
    border-color: $c-tim-blue;

    .label {
      @include t9;

      line-height: 24px;
      top: -13px;
      padding: 0 2px;
      color: $c-tim-blue;

      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &.is-invalid {
    border-color: $c-tim-red;

    .label {
      color: $c-tim-red;
    }

    .input {
      color: $c-tim-red;
    }
  }

  &.is-disabled {
    border-color: $c-tim-gray-light;
    cursor: default;

    .input,
    .label {
      color: $c-tim-gray;
      cursor: default;
    }
  }
}

.input {
  @include base-font;

  background: transparent;
  resize: none;
  outline: none;
  border: none;
  width: 100%;
  flex-grow: 1;
  padding: 0;
  min-height: 32px;

  // autocomplete styles => puts label on top when input is activated by browser
  &:-webkit-autofill ~ .label {
    @include t9;

    line-height: 24px;
    top: -13px;
    padding: 0 2px;
    color: $c-tim-blue;

    &::before {
      opacity: 1;
      visibility: visible;
    }
  }

  &[type="password"]::-ms-reveal,
  &[type="password"]::-ms-clear {
    display: none;
  }
}

.label {
  @include base-font;

  transition: all $base-transition;
  position: absolute;
  left: 16px;
  top: 11px;
  color: $c-tim-gray-dark;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  z-index: 0;
  pointer-events: none;
  max-width: 100%;

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 2.5px;
    background-color: $c-white;
    bottom: 11px;
    z-index: -1;
    left: 0;
    opacity: 0;
    visibility: hidden;
  }
}

.field-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
}

.error-message {
  @include t9;

  color: $c-tim-red;
  line-height: 24px;
  min-height: 18px;
  padding: 2px 0 0 12px;
}

.max-length-count {
  @include t9;

  position: absolute;
  top: 100%;
  left: 8px;
  transform: translateY(-50%);
  color: $c-tim-gray-dark;

  &::before {
    content: "";
    width: 100%;
    height: 2.5px;
    background-color: $c-white;
    position: absolute;
    top: 7px;
    left: 0;
    z-index: -1;
  }
}
</style>
