import { ROUTER_LINKS } from '@enums/global.enums';
import { useImpersonate } from '@impersonate';
import { defineStore } from 'pinia';
import type { UserRole, UserSession, CurrentUser } from '../model';
import { USER_ROLE } from '../model';
import { getUsersRoleList, logoutPimcore } from '../service';
import { setUserForSentry } from '@sentry';

export const useUserCommon = defineStore('user-common-useUserCommon', () => {
  const currentUser = ref<CurrentUser | null>(null);

  const { stopImpersonate } = useImpersonate();

  const setUser = (user: CurrentUser) => {
    currentUser.value = user;
    setUserForSentry(user);
    if(user) {
      localStorage.setItem('userId', user.id);
    }
  };

  const removeUser = () => {
    currentUser.value = null;
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    setUserForSentry(null);
  };

  const setUserSession = (data: UserSession) => {
    if(data.token) {
      localStorage.setItem('token', data.token);
    }
  };

  const logout = async ({ withExpire = false } = {}) => {
    // NOTE: We don't care much if logout in Pimcore fails, because it is only small part of app.
    return logoutPimcore().finally(async () => {
      removeUser();
      await stopImpersonate();
  
      window.location.assign(withExpire ? `${ROUTER_LINKS.LOGIN}?notification=sessionExpired` :ROUTER_LINKS.LOGIN);
    });
  };

  return {
    setUser,
    setUserSession,
    currentUser,
    logout,
  };
});

export const useUserRoles = defineStore('user-common-useUserRoles', () => {
  const userCommon = useUserCommon();

  const roleList = ref<UserRole[]>([]);

  const isSupplier = computed(() => userCommon.currentUser?.role === USER_ROLE.ROLE_SUPPLIER);

  const isLoading = ref(false);
  const fetchRoles = async () => {
    if (roleList.value.length || isLoading.value) {
      return;
    }

    isLoading.value = true;

    const { data } = await getUsersRoleList();
    roleList.value = data;

    isLoading.value = false;
  };

  const isAdmin = computed(() => userCommon.currentUser?.role === USER_ROLE.ROLE_ADMIN);

  const addItemToArrayAsAdmin = <T>(obj: T) => isAdmin.value ? [obj] : [];
  const addItemToArrayAsSupplier = <T>(obj: T) => isSupplier.value ? [obj] : [];

  const adminClass = computed(() => ({ 'is-admin': isAdmin.value }));

  return {
    isSupplier,
    roleList,
    fetchRoles,
    isAdmin,
    addItemToArrayAsAdmin,
    addItemToArrayAsSupplier,
    adminClass,
  };
});

export const useUserUnathorized = defineStore('user-common-useUnathoriizedUser', () => {
  const email = ref('');

  const setEmail = (value: string) => {
    email.value = value;
  };

  return {
    email,
    setEmail,
  };
});
