import { createRouter, createWebHistory } from 'vue-router';
import { ROUTER_LINKS, WIZARD_URL_NAMES } from '@enums/global.enums';
import { ADMIN_ROUTER_LINKS } from '@enums/admin.enums';
import { checkAuth } from './guards';
import { useLoader } from '@ui-base';
import { createCommunicationPanelAdminRoute } from './../packages/communication-panel/route'; // TODO: tests fail when using @communication-panel
import { createShipmentListRoute } from '@shipment-list';
import { createInvoiceDetailsRoute } from '@invoice-details';
import { createSettingsUserRoute } from '@settings-user';
import type { USER_ROLE } from '@user-common';
import { createSupplierScoringRoute } from '@supplier-scoring';
import { createSupplierDashboardRoute } from '@dashboard';

// Admin
const AppAdminPermissions = () => import('@pages/admin-panel/AppAdminPermissions.vue');
const AppAdminUsers = () => import('@pages/admin-panel/AppAdminUsers.vue');
const AppAdminUser = () => import('@pages/admin-panel/AppAdminUser.vue');
const AppAdminSuppliers = () => import('@pages/admin-panel/AppAdminSuppliers.vue');
const AppAdminSupplier = () => import('@pages/admin-panel/AppAdminSupplier.vue');
const AppAdminRegistrations = () => import('@pages/admin-panel/AppAdminRegistrations.vue');
const AppAdminRegistration = () => import('@pages/admin-panel/AppAdminRegistration.vue');

//Products
const AppProductsImportPage = () => import('@pages/AppProducts/AppProductsImportPage.vue');
const AppAddProductsConfiguration = () => import('@pages/AppProducts/AppAddProductsConfiguration.vue');
const AppProductsWizard = () => import('@pages/AppProducts/AppProductsWizard.vue');
const AppAddProductsImport = () => import('@pages/AppProducts/AppAddProductsImport.vue');
const AppAddProductsImportSummary = () => import('@pages/AppProducts/AppAddProductsImportSummary.vue');

//Marketing
const AppMarketingMyMarketing = () => import('@pages/AppMarketing/AppMarketingMyMarketing.vue');
const AppMarketingBanners = () => import('@pages/AppMarketing/AppMarketingBanners.vue');
const AppMarketingCampaigns = () => import('@pages/AppMarketing/AppMarketingCampaigns.vue');

//Sales
const AppSalesOrders = () => import('@pages/AppSales/AppSalesOrders.vue');
const AppSalesOrdersShipping = () => import('@pages/AppSales/AppSalesOrdersShipping.vue');
const AppSalesOrder = () => import('@pages/AppSales/AppSalesOrder.vue');
const AppSalesShipment = () => import('@pages/AppSales/AppSalesShipment.vue');
const AppSalesInvoices = () => import('@pages/AppSales/AppSalesInvoices.vue');
const AppSalesEdiConfiguration = () => import('@pages/AppSales/AppSalesEdiConfiguration.vue');

//User account
const AppUserProfileSettings = () => import('@pages/AppUserProfile/AppUserProfileSettings.vue');

// Sign in/up path
const AppLogin = () => import('@pages/AppLogin.vue');
const AppRegister = () => import('@pages/AppRegister.vue');
const AppThankYouPage = () => import('@pages/AppThankYouPage.vue');
const AppRegisterInactive = () => import('@pages/AppRegisterInactive.vue');
const AppRegisterAlreadyExist = () => import('@pages/AppRegisterAlreadyExist.vue');
const AppLoginInactive = () => import('@pages/AppLoginInactive.vue');
const AppLoginLinkSend = () => import('@pages/AppLoginLinkSend.vue');
const AppLoginResetPassword = () => import('@pages/AppLoginResetPassword.vue');
const AppLoginSetPassword = () => import('@pages/AppLoginSetPassword.vue');
const AppLoginSetPasswordInactive = () => import('@pages/AppLoginSetPasswordInactive.vue');

// Pimcore
const AppProductsManagement = () => import('@pages/AppProductsManagement.vue');

const EmptyRouterView = () => import('@pages/EmptyRouterView.vue');

// Errors
const AppError404 = () => import('@pages/AppError/AppError404.vue');
const AppError503 = () => import('@pages/AppError/AppError503.vue');

const router = createRouter({
  history: createWebHistory(),
  routes: [
    createSupplierDashboardRoute(),
    {
      path: ROUTER_LINKS.PRODUCTS,
      redirect: ROUTER_LINKS.PRODUCTS_IMPORT,
      name: 'AppProducts',
      component: EmptyRouterView,
      meta: {
        pageTitle: 'menu.products',
      },
      children: [
        {
          path: ROUTER_LINKS.PRODUCTS_IMPORT,
          name: 'ParentProductsImport',
          component: EmptyRouterView,
          meta: {
            pageTitle: 'menu.productsImport',
          },
          children: [
            {
              path: '',
              name: 'AppProductsImportPage',
              component: AppProductsImportPage,
            },
            {
              path: `${ROUTER_LINKS.PRODUCTS_IMPORT}/:type?`,
              name: 'AppProductsImportPageType',
              component: AppProductsImportPage,
            },
            {
              path: ROUTER_LINKS.PRODUCTS_IMPORT_CONFIGURATION_ADD,
              name: 'ParentAddProductsConfiguration',
              component: EmptyRouterView,
              meta: {
                pageTitle: 'menu.addingSchema',
              },
              children: [
                {
                  path: '',
                  name: 'AppAddProductsConfiguration',
                  component: AppAddProductsConfiguration,
                },
                {
                  path: `${ROUTER_LINKS.PRODUCTS_IMPORT_CENEO}/:id?/:step?`,
                  name: WIZARD_URL_NAMES.CENEO,
                  component: AppProductsWizard,
                  meta: {
                    nav: true,
                  },
                },
              ],
            },
            {
              path: ROUTER_LINKS.PRODUCTS_IMPORT_ADD,
              name: 'ParentAddProductsImport',
              component: EmptyRouterView,
              meta: {
                pageTitle: 'menu.addingImport',
              },
              children: [
                {
                  path: `${ROUTER_LINKS.PRODUCTS_IMPORT_ADD}/:id`,
                  name: 'AppAddProductsImport',
                  component: AppAddProductsImport,
                },
              ],
            },
            {
              path: ROUTER_LINKS.PRODUCTS_IMPORT_ADD_SUMMARY,
              name: 'ParentAddProductsImportSummary',
              component: EmptyRouterView,
              meta: {
                pageTitle: 'menu.addingImportSummary',
              },
              children: [
                {
                  path: `${ROUTER_LINKS.PRODUCTS_IMPORT_ADD_SUMMARY}/:id`,
                  name: 'AppAddProductsImportSummary',
                  component: AppAddProductsImportSummary,
                },
              ],
            },
          ],
        },
        {
          path: ROUTER_LINKS.PRODUCTS_MANAGEMENT,
          name: 'AppProductsManagement',
          component: AppProductsManagement,
        },
      ],
    },
    {
      path: ROUTER_LINKS.SALES,
      redirect: ROUTER_LINKS.SALES_ORDERS,
      name: 'AppSales',
      component: EmptyRouterView,
      meta: {
        pageTitle: 'menu.sells',
      },
      children: [
        {
          path: ROUTER_LINKS.SALES_EDI_CONFIGURATION,
          name: 'ParentSalesEdiConfiguration',
          component: AppSalesEdiConfiguration,
          meta: {
            pageTitle: 'menu.sells',
          },
        },
        {
          path: ROUTER_LINKS.SALES_ORDERS,
          name: 'ParentSalesOrders',
          component: EmptyRouterView,
          meta: {
            pageTitle: 'menu.orders',
          },
          children: [
            {
              path: '',
              name: 'AppSalesOrders',
              component: AppSalesOrders,
            },
            {
              path: `${ROUTER_LINKS.SALES_ORDERS}/:id`,
              name: 'AppSalesOrder',
              component: AppSalesOrder,
              meta: {
                breadcrumbsTitle: 'menu.order',
                pageTitle: 'menu.orderView',
              },
            },
            {
              path: `${ROUTER_LINKS.SALES_ORDERS_SHIPPING}/:ids`,
              name: 'AppSalesOrdersShipping',
              component: AppSalesOrdersShipping,
              meta: {
                pageTitle: 'menu.ordersShipping',
              },
            },
          ],
        },
        {
          path: ROUTER_LINKS.SALES_SHIPMENTS,
          name: 'ParentSalesShipments',
          component: EmptyRouterView,
          meta: {
            pageTitle: 'menu.shipments',
          },
          children: [
            createShipmentListRoute(),
            {
              path: `${ROUTER_LINKS.SALES_SHIPMENTS}/:id`,
              name: 'AppSalesShipment',
              component: AppSalesShipment,
              meta: {
                pageTitle: 'menu.orderDetails',
              },
            },
          ],
        },
        {
          path: ROUTER_LINKS.SALES_INVOICES,
          name: 'ParentSalesInvoices',
          component: EmptyRouterView,
          meta: {
            pageTitle: 'menu.invoices',
          },
          children: [
            {
              path: '',
              name: 'AppSalesInvoices',
              component: AppSalesInvoices,
            },
            createInvoiceDetailsRoute(),
          ],
        },
      ],
    },
    {
      path: ROUTER_LINKS.MARKETING,
      name: 'AppMarketing',
      redirect: ROUTER_LINKS.MARKETING_MY_MARKETING,
      component: EmptyRouterView,
      meta: {
        pageTitle: 'menu.marketing',
      },
      children: [
        {
          path: ROUTER_LINKS.MARKETING_MY_MARKETING,
          name: 'AppMarketingMyMarketing',
          component: AppMarketingMyMarketing,
          meta: {
            pageTitle: 'menu.myMarketing',
          },
        },
        {
          path: ROUTER_LINKS.MARKETING_BANNERS,
          name: 'AppMarketingBanners',
          component: AppMarketingBanners,
          meta: {
            pageTitle: 'menu.banners',
          },
        },
        {
          path: ROUTER_LINKS.MARKETING_CAMPAIGNS,
          name: 'AppMarketingCampaigns',
          component: AppMarketingCampaigns,
          meta: {
            pageTitle: 'menu.campaigns',
          },
        },
      ],
    },
    createSupplierScoringRoute(),
    createSettingsUserRoute(),
    {
      path: ROUTER_LINKS.SETTINGS,
      name: 'AppUserProfile',
      redirect: ROUTER_LINKS.SETTINGS_SUPPLIER_ACCOUNT,
      component: EmptyRouterView,
      meta: {
        pageTitle: 'menu.myAccount',
      },
      children: [
        {
          path: ROUTER_LINKS.SETTINGS_SUPPLIER_ACCOUNT,
          name: 'AppUserProfileSettings',
          component: AppUserProfileSettings,
          meta: {
            pageTitle: 'menu.settings',
          },
        },
      ],
    },
    {
      path: ADMIN_ROUTER_LINKS.ADMIN_PANEL,
      redirect: ADMIN_ROUTER_LINKS.ADMIN_PANEL_REGISTRATIONS,
      name: 'ParentAdminPanel',
      component: EmptyRouterView,
      meta: {
        pageTitle: 'menu.administration',
        role: 'Administrator' as USER_ROLE.ROLE_ADMIN,
      },
      children: [
        {
          path: ADMIN_ROUTER_LINKS.ADMIN_PANEL_REGISTRATIONS,
          name: 'ParentAdminRegistrations',
          component: EmptyRouterView,
          meta: {
            pageTitle: 'menu.newRegisters',
          },
          children: [
            {
              path: '',
              name: 'AppAdminRegistrations',
              component: AppAdminRegistrations,
            },
            {
              path: `${ADMIN_ROUTER_LINKS.ADMIN_PANEL_REGISTRATIONS}/:id`,
              name: 'AppAdminRegistration',
              component: AppAdminRegistration,
              meta: {
                breadcrumbsTitle: 'menu.newRegister',
                pageTitle: 'menu.registerDetails',
              },
            },
          ],
        },
        createCommunicationPanelAdminRoute(),
        {
          path: ADMIN_ROUTER_LINKS.ADMIN_PANEL_PERMISSIONS,
          name: 'AppAdminPermissions',
          component: AppAdminPermissions,
          meta: {
            pageTitle: 'menu.rights',
          },
        },
        {
          path: ADMIN_ROUTER_LINKS.ADMIN_PANEL_USERS,
          name: 'ParentAdminUsers',
          component: EmptyRouterView,
          meta: {
            pageTitle: 'menu.users',
          },
          children: [
            {
              path: '',
              name: 'AppAdminUsers',
              component: AppAdminUsers,
            },
            {
              path: `${ADMIN_ROUTER_LINKS.ADMIN_PANEL_USERS}/:id`,
              name: 'AppAdminUser',
              component: AppAdminUser,
              meta: {
                pageTitle: 'menu.user',
              },
            },
          ],
        },
        {
          path: ADMIN_ROUTER_LINKS.ADMIN_PANEL_SUPPLIERS,
          name: 'ParentAdminSuppliers',
          component: EmptyRouterView,
          meta: {
            pageTitle: 'menu.suppliers',
          },
          children: [
            {
              path: '',
              name: 'AppAdminSuppliers',
              component: AppAdminSuppliers,
            },
            {
              path: `${ADMIN_ROUTER_LINKS.ADMIN_PANEL_SUPPLIERS}/:id`,
              name: 'AppAdminSupplier',
              component: AppAdminSupplier,
              meta: {
                pageTitle: 'menu.supplier',
              },
            },
          ],
        },
      ],
    },
    {
      path: '/',
      name: 'ParentLoginOrRegister',
      redirect: ROUTER_LINKS.HOME,
      component: EmptyRouterView,
      meta: {
        nav: false,
        hideBreadcrumbs: true,
        allowNotAuthorized: true,
      },
      children: [
        {
          path: ROUTER_LINKS.LOGIN,
          name: 'AppLogin',
          component: AppLogin,
        },
        {
          path: ROUTER_LINKS.REGISTER,
          name: 'AppRegister',
          component: AppRegister,
          meta: {
            tokenRequired: true,
          },
        },
        {
          path: ROUTER_LINKS.REGISTER_THANK_YOU,
          name: 'AppThankYouPage',
          component: AppThankYouPage,
        },
        {
          path: ROUTER_LINKS.REGISTER_INACTIVE,
          name: 'AppRegisterInactive',
          component: AppRegisterInactive,
        },
        {
          path: ROUTER_LINKS.REGISTER_ALREADY_EXIST,
          name: 'AppRegisterAlreadyExist',
          component: AppRegisterAlreadyExist,
        },
        {
          path: ROUTER_LINKS.LOGIN_INACTIVE,
          name: 'AppLoginInactive',
          component: AppLoginInactive,
        },
        {
          path: `${ROUTER_LINKS.LOGIN_LINK_SEND}/:from`,
          name: 'AppLoginLinkSend',
          component: AppLoginLinkSend,
        },
        {
          path: ROUTER_LINKS.RESET_PASSWORD,
          name: 'AppLoginResetPassword',
          component: AppLoginResetPassword,
        },
        {
          path: ROUTER_LINKS.SET_PASSWORD,
          name: 'AppLoginSetPassword',
          component: AppLoginSetPassword,
          meta: {
            tokenRequired: true,
          },
        },
        {
          path: ROUTER_LINKS.SET_PASSWORD_INACTIVE,
          name: 'AppLoginSetPasswordInactive',
          component: AppLoginSetPasswordInactive,
        },
      ],
    },
    {
      path: ROUTER_LINKS.ERROR_503,
      name: 'AppError503',
      component: AppError503,
      meta: {
        hideBreadcrumbs: true,
        nav: false,
      },
    },
    {
      path: '/:catchAll(.*)',
      name: 'AppError404',
      component: AppError404,
      meta: {
        hideBreadcrumbs: true,
      },
    },
  ],
},
);

router.beforeEach(checkAuth);

let isSeenVersion = false;
router.beforeEach(() => {
  if (isSeenVersion) {
    return;
  }

  isSeenVersion = true;

  const tag = import.meta.env.VITE_COMMIT_TAG;
  if (tag) {
    console.log(tag);
  }
});

router.beforeEach((to, from, next) => {
  if (to.meta.withLoader === false || from.meta.withLoader === false) {
    next();
    return;
  }

  const { startLoading } = useLoader();
  startLoading();
  next();
});
router.afterEach(() => {
  const { stopLoading } = useLoader();
  stopLoading();
});

export default router;
