import { useModal } from '@ui-base';
import type { ActionListItem } from '../model';
import { REGISTRATION_ACTION, REGISTRATION_STATUS } from '../model';
import { updateStatus } from '../service';
import AddCommentModal from '../components/comment/AddCommentModal.vue';
import DeclineReasonModal from '../components/list-actions/DeclineReasonModal.vue';
import _omit from 'lodash/omit';

const isNew = (item: ActionListItem) => item.status === REGISTRATION_STATUS.NEW;
const isAccepted = (item: ActionListItem) => item.status === REGISTRATION_STATUS.ACCEPTED;
const isDeclined = (item: ActionListItem) => item.status === REGISTRATION_STATUS.DECLINED;
const isArchived = (item: ActionListItem) => item.status === REGISTRATION_STATUS.ACCEPTED_ARCHIVED
  || item.status === REGISTRATION_STATUS.DECLINED_ARCHIVED
  || item.status === REGISTRATION_STATUS.NEW_ARCHIVED
  || item.status === REGISTRATION_STATUS.VERIFICATION_ARCHIVED;

const actionTitleMap = new Map([
  [REGISTRATION_ACTION.ACCEPT, 'Zaakceptuj'],
  [REGISTRATION_ACTION.DECLINE, 'Odrzuć'],
  [REGISTRATION_ACTION.UNDER_VERIFICATION, 'Do weryfikacji'],
  [REGISTRATION_ACTION.COMMENT, 'Dodaj komentarz'],
  [REGISTRATION_ACTION.POST_MESSAGE, 'Zresetuj hasło'],
]);

export const useActionList = () => {
  const { showConfirmationModal, showModal } = useModal();

  const openStatusActionModal = (payload: {
    items: ActionListItem[],
    action: REGISTRATION_ACTION,
    beforeStatusChange?: () => void,
    afterStatusChange?: () => void,
  }) => {
    if (payload.items.length === 0) {
      return;
    }

    const actionPayload = _omit(payload, ['beforeStatusChange', 'afterStatusChange']);

    switch (payload.action) {
      case REGISTRATION_ACTION.ACCEPT: {
        showConfirmationModal({
          content: `Czy na pewno chcesz zmienić status użytkownika na zatwierdzony?`,
          callback: async () => {
            payload.beforeStatusChange?.();
            await handleActionList(actionPayload);
            payload.afterStatusChange?.();
          },
        });
        break;
      }
      case REGISTRATION_ACTION.DECLINE: {
        showModal(
          DeclineReasonModal,
          {
            callback: async (declineReason: string) => {
              payload.beforeStatusChange?.();
              await handleActionList({ ...actionPayload, declineReason });
              payload.afterStatusChange?.();
            },
          },
        );
        break;
      }
      case REGISTRATION_ACTION.POST_MESSAGE: {
        showConfirmationModal({
          content: `Czy na pewno chcesz wysłać prośbę o zmianę hasła?`,
          callback: async () => {
            payload.beforeStatusChange?.();
            await handleActionList(actionPayload);
            payload.afterStatusChange?.();
          },
        });
        break;
      }
      case REGISTRATION_ACTION.UNDER_VERIFICATION: {
        showConfirmationModal({
          content: `Czy na pewno chcesz zmienić status użytkownika na zweryfikowany?`,
          callback: async () => {
            payload.beforeStatusChange?.();
            await handleActionList(actionPayload);
            payload.afterStatusChange?.();
          },
        });
        break;
      }
      case REGISTRATION_ACTION.COMMENT: {
        showModal(
          AddCommentModal,
          {
            id: payload.items[0].id,
            callback: () => payload.afterStatusChange?.(),
          },
        );
        break;
      }
    }
  };

  return {
    isNew,
    isAccepted,
    isDeclined,
    isArchived,
    openStatusActionModal,
    actionTitleMap,
  };
};

// handler triggers action for a list of items to change status
function handleActionList(payload: {
  items: ActionListItem[],
  action: REGISTRATION_ACTION,
  declineReason?: string
}) {
  return Promise.all(payload.items.map((item) => updateStatus({
    id: item.id,
    action: payload.action,
    successMessage: createActionSuccessMessage(payload.action),
    declineReason: payload.declineReason,
  })));
}

// here you can apply custom success messages for each action
function createActionSuccessMessage (action: REGISTRATION_ACTION): string  {
  let successMessage: string = '';

  switch (action) {
    case REGISTRATION_ACTION.POST_MESSAGE: {
      successMessage = 'Wiadomość z linkiem do resetu hasła wysłana.';
      break;
    }
    default: {
      successMessage = 'Wysłane';
    }
  }

  return successMessage;
}
