<template>
  <UiFormTextareaElement
    id="messageSendTextarea"
    v-model="input"
    name="messageSendTextarea"
    :is-disabled="isLoading"
    placeholder="Wpisz wiadomość"
    max-length="3000"
    :max-height="300"
    :prevent-enter-submit="false"
    @submit="handleSend"
  >
    <template
      v-if="fileList.length"
      #above-input
    >
      <FileListDecorator>
        <FileDisplayNew
          v-for="(file, index) in fileList"
          :key="index"
          :file="file"
          data-t="message-send-textarea-file"
          @delete="deleteFile(index)"
        />
      </FileListDecorator>
    </template>
    <template #after-input>
      <div class="action-buttons">
        <GlobalIcon
          name="attach_file_add"
          class="icon-attach"
          is-symbol
          data-t="message-send-textarea-open-file-dialog"
          :class="{ 'is-disabled': isLoading }"
          title="Dodaj plik"
          @click="openFileDialog"
        />
        <GlobalButton
          class="button-send"
          :disabled="isDisabled"
          data-t="message-send-textarea-send"
          @click="handleSend"
        >
          <GlobalIcon
            name="send"
            class="icon-send"
          />
        </GlobalButton>
      </div>
    </template>
  </UiFormTextareaElement>
</template>

<script setup lang="ts">
import { UiFormTextareaElement } from '@ui-form';
import FileDisplayNew from './file/FileDisplayNew.vue';
import FileListDecorator from '../decorators/FileListDecorator.vue';
import { useFileList } from '../../composables/common/useFileList';

type Props = {
  isLoading: boolean
}
const props = defineProps<Props>();

const input = ref('');

const $emit = defineEmits<{
  (event: 'send', data: { input: string, files: File[] }): void
}>();

const isLoading = computed(() => props.isLoading);
const {
  fileList,
  deleteFile,
  openFileDialog,
} = useFileList({
  isDisabled: isLoading,
});

const isDisabled = computed(() => {
  const hasNoContentOrFiles = (!input.value && !fileList.value.length);
  const hasInvalidFiles = fileList.value.some((file) => file.isInvalidSize);

  return hasNoContentOrFiles || props.isLoading || hasInvalidFiles;
});

const handleSend = () => {
  if (isDisabled.value) {
    return;
  }

  $emit(
    'send',
    {
      input: input.value,
      files: fileList.value,
    },
  );

  // Edge case: zresetuj input i fileList po wysłaniu wiadomości. W przypadku jak textarea jest zablokowany, to nie zresetuje się w normalnym trybie.
  nextTick(() => {
    input.value = '';
    fileList.value = [];
  });
};
</script>

<style lang="scss" scoped>
.action-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.icon-attach {
  font-size: 16px;
  padding: 8px 10px;
  color: $c-tim-gray-dark;
  cursor: pointer;
  transition: color $base-transition;
  border-right: 1px solid $c-tim-gray-light;

  &:hover {
    color: $text-color-primary;
  }

  &.is-disabled {
    pointer-events: none;
  }
}

.icon-send {
  font-size: 16px;
}

.button-send {
  width: 44px;
  height: 32px;
  margin-left: 10px;
}
</style>
