import baseService from '@services/baseService';
import { useCookies } from '@vueuse/integrations/useCookies';
import { useRequest } from '@request';

const COOKIE_KEY = '__IMPERSONATE_USER__';
const COOKIE_OPTIONS = {
  maxAge: import.meta.env.VITE_IMPERSONATION_TIMEOUT || 60 * 30, // 30m
  path: '/',
  secure: true,
  sameSite: true,
};

const isLoadingImpersonate = ref(false);
export default () => {
  const { callRequest } = useRequest();
  const cookies = useCookies();

  const isImpersonating = computed(() => !!cookies.get(COOKIE_KEY) && !isLoadingImpersonate.value);
    
  // check when fe session is over and stop be session
  // NOTE: should be called only once
  function checkImpersonateSession() {
    if (isImpersonating.value) {
      const watchStop = watch(isImpersonating, () => {
        if (!isImpersonating.value) {
          stopImpersonate({ force: true });
          watchStop();
        }
      });
    }
  }

  async function startImpersonate(email: string) {
    isLoadingImpersonate.value = true;
    // start fe session
    cookies.set(COOKIE_KEY, 1, COOKIE_OPTIONS);
    // start be session
    const { data, hasError } = await callRequest(baseService.get(`/users/reload?_switch_user=${email}`));
    if (hasError) {
      // remove fe session
      cookies.remove(COOKIE_KEY, COOKIE_OPTIONS);
      isLoadingImpersonate.value = false;
      return;
    }

    localStorage.setItem('token', data?.token);
    // app needs to assign user data again, which require reload
    window.location.assign('/');
    nextTick(() => {
      isLoadingImpersonate.value = false;
    });
  }

  async function stopImpersonate({ force = false } = {}) {
    if (!isImpersonating.value && !force) {
      return;
    }

    // remove fe session
    cookies.remove(COOKIE_KEY, COOKIE_OPTIONS);
    // remove be session
    await callRequest(baseService.get(`/users/reload?_switch_user=_exit`));
  }

  return {
    stopImpersonate,
    startImpersonate,
    isImpersonating,
    checkImpersonateSession,
    isLoadingImpersonate,
  };
};
