import baseService from '@services/baseService';
import { useRequest } from '@request';
import type { FileDownloadProps } from './model';
import type { AxiosRequestConfig } from 'axios';
import { downloadFile } from './helpers';

export const fetchFile = async (params: FileDownloadProps): Promise<Blob> => {
  const { callRequest } = useRequest();

  const { link, format, payload, method } = params;

  const options: AxiosRequestConfig = {
    headers: {
      'Accept': format,
    },
    responseType: 'blob',
  };

  const { data } = await callRequest<Blob>(
    method === 'get'
      ? baseService.get(link, options)
      : baseService.post(
        link,
        { ...(payload ? payload : {}) },
        options,
      )
    ,
  );

  return data;
};

export const startFileDownload = async (params: FileDownloadProps): Promise<void> => {
  const data = await fetchFile(params);

  if (data) {
    downloadFile(data, params.name, params.format.split('/')[1]);
  }
};
