export enum REGISTRATION_ACTION {
  ACCEPT = 'accept',
  DECLINE = 'decline',
  POST_MESSAGE = 'post-message',
  UNDER_VERIFICATION = 'under_verification',
  COMMENT = 'comment'
}

export enum REGISTRATION_STATUS {
  NEW = 'NEW',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  VERIFICATION = 'VERIFICATION',
  NEW_ARCHIVED = 'NEW_ARCHIVED',
  ACCEPTED_ARCHIVED = 'ACCEPTED_ARCHIVED',
  DECLINED_ARCHIVED = 'DECLINED_ARCHIVED',
  VERIFICATION_ARCHIVED = 'VERIFICATION_ARCHIVED',
}

export type ActionListItem = {
  id: string
  status: REGISTRATION_STATUS
  commentsCount?: number
}

export type CommentItem = {
  comment: string,
  updatedAt: string,
  id: string,
  author: string,
  edited: boolean
}
