<template>
  <div class="message-options">
    <span class="header">ZARZĄDZANIE WIADOMOŚCIĄ</span>
    <div
      data-t="message-options-edit-button"
      class="option-button"
      @click.stop="$emit('edit-message')"
    >
      <GlobalIcon
        class="option-button__icon is-edit"
        name="edit"
        is-symbol
      />
      <span class="option-button__header">Edytuj wiadomość</span>
    </div>
    <div
      data-t="message-options-delete-button"
      class="option-button"
      @click.stop="$emit('delete-message')"
    >
      <GlobalIcon
        class="option-button__icon is-delete"
        name="delete_forever"
        is-symbol
      />
      <span class="option-button__header">Usuń wiadomość</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { MessageItemWithStatus } from '../../model/MessageItem';

type Props = Pick<MessageItemWithStatus, 'isDeleted' | 'isEdited'>
defineProps<Props>();

const $emit = defineEmits<{
  (event: 'edit-message'): void,
  (event: 'delete-message'): void,
}>();
</script>

<style lang="scss" scoped>
.message-options {
  display: grid;
  height: fit-content;
  width: fit-content;
  box-shadow: $dropdown-shadow;
  border-radius: $base-radius;
  border: 1px solid $c-tim-gray-light;
  background: $c-white;
}

.header {
  @include t9;

  padding: 16px 16px 4px;
  color: $c-tim-gray-dark;
  text-transform: uppercase;
}

.option-button {
  display: grid;
  padding: 12px 14px;
  gap: 8px;
  grid-auto-flow: column;
  grid-template-columns: 24px auto;

  @include custom-button;

  &:first-of-type {
    border-bottom: 1px solid $c-tim-gray-lightest;
  }

  &__icon {
    font-size: 16px;
    padding: 4px;

    &.is-edit {
      color: $c-tim-blue-dark;
    }

    &.is-delete {
      color: $c-tim-red;
    }
  }

  &__header {
    @include t7;
  }
}
</style>
