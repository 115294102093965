import { storeToRefs } from 'pinia';
import { createNewMessageInThread } from '../../service/supplier';
import { useCommonState } from './useCommonState';
import type { CreateSupplierMessageInThread } from '../../model/Payload';
import { makeRandomId } from '../../mock';
import { mapMessageItem } from '../../mappers/common';
import type { MessageItem } from '../../model/MessageItem';
import { useUnreadThreads } from '../common/useUnreadThreads';

export const useMessageCreate = () => {
  const { refreshThreadDetails } = useCommonState();
  const { refreshUnreadThreadsCount } = useUnreadThreads();
  const { threadDetails } = storeToRefs(useCommonState());

  const createMessage = async (loggedUserId: string, payload: CreateSupplierMessageInThread) => {
    const temporaryMessage = mapMessageItem({
      id: makeRandomId(),
      user: {
        id: loggedUserId,
        name: 'Ty',
      },
      content: payload.content,
      createdAt: new Date().toISOString(),
      files: [],
      isDeleted: false,
      isEdited: false,
    });
    temporaryMessage.isSending = (true as MessageItem['isSending']);
    threadDetails.value.messageList.push(temporaryMessage);

    const temporaryMessageInThreadDetails = threadDetails.value.messageList.find((message) => message.id === temporaryMessage.id);

    try {
      const { hasError } = await createNewMessageInThread(payload);
      temporaryMessageInThreadDetails.isSending = (false as MessageItem['isSending']);

      if (hasError) {
        temporaryMessageInThreadDetails.hasError = (true as MessageItem['hasError']);
        return;
      }

      refreshThreadDetails();
      refreshUnreadThreadsCount();
    } catch {
      temporaryMessageInThreadDetails.isSending = (false as MessageItem['isSending']);
      temporaryMessageInThreadDetails.hasError = (true as MessageItem['hasError']);
    }
  };

  return {
    createMessage,
  };
};
