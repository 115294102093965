<template>
  <div class="segment-select-list">
    <div class="standard-list">
      <div
        v-for="(segment) in list.restSegmentList"
        :key="segment.id"
        class="item"
        :title="segment.name"
        data-t="segment-select-list-item"
        @click.stop="handleSegmentClick(segment)"
      >
        <span class="item__text">{{ segment.name }}</span>
        <GlobalIcon
          class="item__icon"
          name="chevron_right"
        />
      </div>
    </div>
    <div class="separator" />
    <GlobalButton
      outlined
      no-border
      icon="add"
      data-t="segment-select-list-item-not-standard"
      @click.stop="handleSegmentClick(list.notStandardSegment)"
    >
      {{ list.notStandardSegment.name }}
    </GlobalButton>
  </div>
</template>

<script setup lang="ts">
import type { SegmentItem, SegmentList } from '../../model/Segment';

type Props = {
  list: SegmentList
}
defineProps<Props>();
const $emit = defineEmits<{
  (event: 'segment-selected', payload: SegmentItem): void
}>();

const handleSegmentClick = (payload: SegmentItem) => {
  $emit('segment-selected', payload);
};
</script>

<style lang="scss" scoped>
.segment-select-list {
  display: grid;
  gap: 16px;
}

.standard-list {
  display: grid;
  border: 1px solid $c-tim-gray-light;
  border-radius: $base-radius;
}

.item {
  cursor: pointer;
  padding: 16px 12px;
  background: $c-tim-gray-lightest-alt;
  display: grid;
  grid-template-columns: 1fr auto;
  transition: background-color $base-transition;

  &:not(:last-child) {
    border-bottom: 1px solid $c-tim-gray-lightest;
  }

  &:hover {
    background: $c-tim-gray-lightest;
  }

  &__text {
    @include one-line-text-with-dots;
  }

  &__icon {
    color: $c-tim-blue;
  }
}

.separator {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background: $c-tim-gray-light;
  }
}
</style>
