<template>
  <div
    v-if="statusItem"
    class="status-indicator"
    :class="`status-indicator--${statusItem.color}`"
  >
    {{ statusItem.text }}
  </div>
</template>

<script setup lang="ts" generic="T = string">
import type { GLOBAL_STATUS_COLOR } from '../model';

interface Props {
  status: T,
  statusList: { name: T, text: string, color: GLOBAL_STATUS_COLOR }[],
  width?: string
}

const props = defineProps<Props>();

const normalizedWidth = computed(() => props.width || 'auto');
const statusItem = computed(() => props.statusList.find(({ name }) => props.status === name));
</script>

<style lang="scss" scoped>

.status-indicator {
  width: v-bind(normalizedWidth);
  min-width: 127px;
  padding: 8px 4px;
  text-align: center;
  line-height: 16px;
  display: inline-block;

  &::first-letter {
    text-transform: capitalize;
  }

  &--blue {
    color: $c-tim-blue;
    background-color: $c-tim-blue-light;
  }

  &--green {
    color: $c-tim-green;
    background-color: $c-tim-green-light;
  }

  &--light-green {
    color: $c-tim-green;
    background-color: $c-tim-green-light;
  }

  &--red {
    color: $c-tim-red;
    background-color: $c-tim-red-light;
  }

  &--orange {
    color: $c-tim-orange;
    background-color: $c-tim-orange-light;
  }

  &--gray {
    color: $c-tim-gray-dark;
    background-color: $c-tim-gray-light;
  }

  &--pink {
    color: $c-tim-pink;
    background-color: $c-tim-pink-light;
  }
}
</style>
