<template>
  <CommentModal
    v-model="comment"
    :is-loading="isLoading"
    @save="handleSave"
  />
</template>

<script setup lang="ts">
import { useModal } from '@ui-base';
import CommentModal from './CommentModal.vue';
import { addComment } from '../../service';

interface Props {
  id: string,
  callback: () => void
}
const props = defineProps<Props>();

const { hideModal } = useModal();

const isLoading = ref(false);
const comment = ref('');

const handleSave = async () => {
  if (!comment.value.length) {
    hideModal();
    return;
  }

  isLoading.value = true;
  await addComment({
    id: props.id,
    comment: comment.value,
  });

  props.callback();
  hideModal();
  isLoading.value = false;
};
</script>
  
