<template>
  <div
    class="thread-status"
    :class="className"
  >
    {{ text }}
  </div>
</template>

<script setup lang="ts">
import { THREAD_STATUS } from '../../model/Thread';

type Props = {
  status: THREAD_STATUS
}
const props = defineProps<Props>();

const text = computed(() => {
  switch (props.status) {
    case THREAD_STATUS.ACTIVE:
      return 'AKTYWNY';
    case THREAD_STATUS.FINISHED:
      return 'ZAKOŃCZONY';
    case THREAD_STATUS.DELETED:
      return 'USUNIĘTY';
    default:
      return '';
  }
});

const className = computed(() => {
  switch (props.status) {
    case THREAD_STATUS.ACTIVE:
      return 'is-active';
    case THREAD_STATUS.FINISHED:
      return 'is-finished';
    case THREAD_STATUS.DELETED:
      return 'is-deleted';
    default:
      return '';
  }
});
</script>

<style lang="scss" scoped>
.thread-status {
  @include t9;

  display: inline-flex;
  max-width: max-content;
  font-weight: 600;
  padding: 4px 8px;
  border-radius: 2px;

  &.is-active {
    color: $c-tim-green;
    background: $c-tim-green-light;
  }

  &.is-finished {
    color: $c-tim-gray-dark;
    background: $c-tim-gray-light;
  }

  &.is-deleted {
    color: $c-tim-red;
    background: $c-tim-red-light;
  }
}
</style>
