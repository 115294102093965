import { useCookies } from '@vueuse/integrations/useCookies';

export { default as FeatureFlag } from './FeatureFlag.vue';
export const useFeatureFlag = (flagName: FEATURE_FLAGS) => {
  const cookies = useCookies();
  
  const isFlagEnabled = computed<boolean>(() => cookies.get(flagName));

  const returnForFlag = <Payload, Fallback>(payload: Payload, fallback: Fallback): Payload | Fallback => {
    if (isFlagEnabled.value) {
      return payload;
    }

    return fallback;
  };

  return {
    isFlagEnabled,
    returnForFlag,
  };
};

export enum FEATURE_FLAGS {
  ENABLE_RABEN_CARRIER = 'enableRabenCarrier',
  DISABLE_SUPPLIER_RELOAD = 'disableSupplierReload',
}
