<template>
  <PageViewWrapper>
    <slot name="header" />
    <PageViewContentWrapper
      ref="contentRef"
      padding="0"
      class="content"
    >
      <ContentLoader v-if="!threadDetails" />
      <MessageBoxList
        v-else
        :message-list="threadDetails.messageList"
        :admin="threadDetails.admin"
        :supplier="threadDetails.supplier"
        :logged-user="loggedUser"
      >
        <template #item="item">
          <MessageBox
            v-show="editingId !== item.id"
            v-bind="item"
          >
            <template #options="{ close }">
              <MessageOptions
                :is-deleted="item.isDeleted"
                :is-edited="item.isEdited"
                @delete-message="() => handleDelete(item, close)"
                @edit-message="() => handleEditStart(item.id, close)"
              />
            </template>
          </MessageBox>
          <MessageBoxEditMode
            v-if="editingId === item.id"
            v-bind="item"
            @save-edit="handleEditSave"
            @decline-edit="handleEditStart(null)"
          />
        </template>
      </MessageBoxList>
      <div class="message-send-textarea">
        <ContentLoader v-if="!threadDetails" />
        <MessageSendTextarea
          v-else-if="threadDetails.status === THREAD_STATUS.ACTIVE"
          :is-loading="false"
          @send="handleSend"
        />
        <GlobalNotification
          v-else
          :model-value="true"
          :type="GLOBAL_NOTIFICATION_TYPES.ERROR"
          :closeable="false"
          margin="0"
        >
          <div class="notification-content">
            Ten wątek został zakończony przez administratora.
            <span class="notification-content__subtext">Jeżeli problem nie został rozwiązany prosimy o wysłanie nowej wiadomości.</span>
          </div>
        </GlobalNotification>
      </div>
    </PageViewContentWrapper>
  </PageViewWrapper>
</template>

<script setup lang="ts">
import type { SupplierThreadDetails } from '../../../model/Thread';
import type { LoggedUser } from '../../../model/LoggedUser';
import { THREAD_STATUS } from '../../../model/Thread';
import type { CreateSupplierMessageInThread } from '../../../model/Payload';
import MessageBoxList from '../../common/MessageBoxList.vue';
import MessageSendTextarea from '../../common/MessageSendTextarea.vue';
import ContentLoader from '../../common/ContentLoader.vue';
import PageViewContentWrapper from '../../decorators/PageViewContentWrapper.vue';
import PageViewWrapper from '../../decorators/PageViewWrapper.vue';
import { GLOBAL_NOTIFICATION_TYPES } from '@ui-global';
import { useMessageCreate } from '../../../composables/supplier/useMessageCreate';
import { useScrollMessageBox } from '../../../composables/common/useScrollMessageBox';
import MessageBox from '../../common/MessageBox.vue';
import MessageOptions from '../../common/MessageOptions.vue';
import MessageBoxEditMode from '../../common/MessageBoxEditMode.vue';
import type { MessageItem } from '../../../model/MessageItem';
import { useMessageOptions } from '../../../composables/common/useMessageOptions';
import { useCommonState } from '../../../composables/supplier/useCommonState';

type Props = {
  loggedUser: LoggedUser
  threadDetails: Pick<SupplierThreadDetails, 'messageList' | 'admin' | 'supplier' | 'id' | 'status'> | null
}
const props = defineProps<Props>();

// MESSAGE OPTIONS
const { refreshThreadDetails } = useCommonState();
const { editMessage, deleteMessage } = useMessageOptions();
const editingId = ref<MessageItem['id'] | null>(null);
const handleEditStart = (payload: MessageItem['id'] | null, close?: () => void) => {
  close?.();
  editingId.value = payload;
};
const handleEditSave = async (payload: { content: string, files: File[] }) => {
  await editMessage({
    payload: {
      id: editingId.value,
      ...payload,
    },
    threadDetails: props.threadDetails,
  });
  editingId.value = null;
  refreshThreadDetails();
};
const handleDelete = async (payload: MessageItem, close: () => void) => {
  close();
  await deleteMessage({
    payload,
    threadDetails: props.threadDetails,
  });
  refreshThreadDetails();
};

// NEW_MESSAGE
const { createMessage } = useMessageCreate();
const handleSend = async (payload: { input: string, files: File[] }) => {
  if (!props.loggedUser) {
    console.warn('Użytkownik niezalogowany, wiadomość nie została wysłana');
    return;
  }

  await createMessage(props.loggedUser.id, {
    threadId: props.threadDetails.id,
    content: payload.input as CreateSupplierMessageInThread['content'],
    files: payload.files,
  });
};

const contentRef = useTemplateRef('contentRef');
const messageList = computed(() => props.threadDetails?.messageList || []);
const { setScrollToBottom } = useScrollMessageBox();
setScrollToBottom(
  contentRef,
  messageList,
);
</script>

<style lang="scss" scoped>
.content {
  padding-bottom: 72px;
  background: $c-tim-gray-lightest-alt;
}

.message-send-textarea {
  min-height: 72px;
  padding: 12px;
  background: $c-white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.notification-content {
  display: flex;
  flex-direction: column;

  &__subtext {
    @include t9;
  }
}
</style>
