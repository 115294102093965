<template>
  <GlobalModal
    :model-value="true"
    header="Odrzuć"
    :is-loading="isLoading"
    :vertical-padding="10"
    @save="handleSave"
    @close="hideModal"
  >
    <Form ref="form">
      <UiFormTextareaElement
        id="declineReason"
        v-model="declineReason"
        label="Wpisz powód odrzucenia wniosku"
        name="declineReason"
        max-length="1000"
        :max-height="200"
        rows="3"
      />
    </Form>
  </GlobalModal>
</template>

<script setup lang="ts">
import { useModal } from '@ui-base';
import { UiFormTextareaElement } from '@ui-form';
import { Form } from 'vee-validate';

type Props = {
  callback: (declineReason: string) => void
}
const props = defineProps<Props>();

const { hideModal } = useModal();

const isLoading = ref(false);
const declineReason = ref('');

const form = useTemplateRef('form');
const handleSave = async () => {
  const validatedForm = await form.value.validate();

  if (!validatedForm.valid) {
    return;
  }

  isLoading.value = true;
  props.callback(declineReason.value);
  hideModal();
  isLoading.value = false;
};
</script>
