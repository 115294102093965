<template>
  <GlobalModal
    :model-value="true"
    header="Komentarz"
    :is-loading="isLoading"
    :vertical-padding="8"
    @save="$emit('save')"
    @close="hideModal"
  >
    <UiFormTextareaElement
      id="registerListComment"
      v-model="comment"
      label="Twój komentarz"
      name="registerListComment"
      :max-length="300"
      :max-height="120"
      rows="4"
    />
  </GlobalModal>
</template>

<script setup lang="ts">
import { useModal } from '@ui-base';
import { UiFormTextareaElement } from '@ui-form';

interface Props {
  modelValue: string,
  isLoading: boolean,
}
const props = defineProps<Props>();
const $emit = defineEmits<{
  (event: 'save'): void
  (event: 'update:model-value', value: string)
}>();

const { hideModal } = useModal();

const comment = computed({
  get(){
    return props.modelValue;
  },
  set(value) {
    $emit('update:model-value', value);
  },
});
</script>
  
