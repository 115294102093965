import type { FormatedDateWithTime } from '@ui-base';
import type { SegmentItem } from './Segment';
import type { MessageItem } from './MessageItem';
import type { SupplierDetails } from './Supplier';
import type { AdminItem } from './Admin';

export enum THREAD_STATUS {
  FINISHED = 'FINISHED',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
}

type ThreadTitle = Distinct<string, 'ThreadTitle'>
export type ThreadId = Distinct<string, 'ThreadId'>
type ThreadNumber = Distinct<string, 'ThreadNumber'>
export type ThreadRequireUserAction = Distinct<boolean, 'ThreadRequireUserAction'>
export type ThreadHasNote = Distinct<boolean, 'ThreadHasNote'>

// Supplier types
export type SupplierThreadItem = {
  id: ThreadId,
  title: ThreadTitle,
  status: THREAD_STATUS,
  segment: Pick<SegmentItem, 'name'>,
  number: ThreadNumber,
  createdAt: FormatedDateWithTime,
  requireUserAction: ThreadRequireUserAction,
}

export type SupplierThreadDetails = {
  id: ThreadId,
  admin: {
    id: AdminItem['id'] | null
  },
  supplier: Pick<SupplierDetails, 'id'>,
  status: THREAD_STATUS,
  segment: Pick<SegmentItem, 'name'>,
  number: ThreadNumber,
  requireUserAction: ThreadRequireUserAction,
  messageList: MessageItem[]
}

// Admin types
export type AdminThreadItem = {
  id: ThreadId,
  admin: {
    id: AdminItem['id'] | null
    name: AdminItem['name'] | null
  },
  supplier: Pick<SupplierDetails, 'id' | 'name' | 'mfgid'>,
  title: ThreadTitle,
  status: THREAD_STATUS,
  segment: Pick<SegmentItem, 'name'>,
  number: ThreadNumber,
  createdAt: FormatedDateWithTime,
  requireUserAction: ThreadRequireUserAction,
  hasNote: ThreadHasNote,
}

export type AdminThreadDetails = {
  id: ThreadId,
  title: ThreadTitle,
  segment: {
    name: SegmentItem['name']
  },
  admin: {
    id: AdminItem['id'] | null
    name: AdminItem['name'] | null
  },
  supplier: Pick<SupplierDetails, 'id' | 'name' | 'mfgid' | 'nip'>,
  threadCreatorName: Distinct<string, 'ThreadCreatorName'>,
  createdAt: FormatedDateWithTime,
  note: Distinct<string, 'ThreadNote'> | null,
  isFavourite: Distinct<boolean, 'ThreadIsFavorite'>,
  messageList: MessageItem[]
  status: THREAD_STATUS
}
