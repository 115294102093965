<template>
  <div>
    <slot />
    <div v-if="isExpanded">
      <span class="text__more">
        <slot name="expanded" />
        <span
          v-if="isExpanded"
          data-t="hide-button"
          class="text__button"
          @click="isExpanded = !isExpanded"
        >
          {{ expandText }}
        </span>
      </span>
    </div>&nbsp;
    <span
      v-if="!isExpanded"
      data-t="show-button"
      class="text__button"
      @click="isExpanded = !isExpanded"
    >
      {{ expandText }}
    </span>
  </div>
</template>

<script setup lang="ts">

const { t } = useI18n();

const isExpanded = ref(false);

const expandText = computed(() => isExpanded.value ? t('readLess') : t('readMore'));
</script>

<style lang="scss" scoped>
.text__more {
  white-space: normal;
}

.text__button {
  color: $c-tim-orange;
  cursor: pointer;
}
</style>
