import { useUserRoles } from './useUserStore';

export const useUserPermission = () => {
  const userRoles = useUserRoles();
  
  // Określa, czy użytkownik ma uprawnienia do edycji adresu siedziby firmy
  const canEditSupplierMainAddress = computed(() => userRoles.isAdmin);

  // Określa, czy użytkownik ma uprawnienia do edycji danych administratora konta głównego
  const canEditSupplierMainAccountInfo = computed(() => userRoles.isAdmin);

  return {
    canEditSupplierMainAddress,
    canEditSupplierMainAccountInfo,
  };
};
