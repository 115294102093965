import type { AxiosError } from 'axios';
import type { ErrorInfo } from './model';

export function getErrorMessage (error: AxiosError<ErrorInfo>): {message: string, skipNotification: boolean} {
  const validationBEErrorMessage = error.response?.data?.violations?.map((violation) => violation?.message || '') || [];
  const skipNotification = !!error.response?.data?.skipNotification;
  
  if (validationBEErrorMessage.length) {
    return {
      message: validationBEErrorMessage.join(' '),
      skipNotification,
    };
  }
  
  return {
    message: error.response?.data?.message || 'Pojawił się błąd. W razie problemów skontaktuj się z nami na adres markettim@tim.pl',
    skipNotification,
  };
}
