<template>
  <GlobalTooltip
    max-width="400"
    center
    class="info-tooltip"
    placement="auto"
  >
    <GlobalIcon name="info" />
    <template #tooltip>
      <span class="content">
        <slot />
      </span>
    </template>
  </GlobalTooltip>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>
.info-tooltip {
  margin-left: 4px;
}

.content {
  text-transform: none;
}
</style>
