<template>
  <img
    v-if="link"
    :src="link"
  >
</template>

<script setup lang="ts">
import type { FileDownloadProps } from '../model';
import { fetchFile } from '../service';
import { computedAsync, useObjectUrl } from '@vueuse/core';

const props = defineProps<FileDownloadProps>();
  
const link = computedAsync(
  async () => {
    try {
      const file = await fetchFile(props);
      return useObjectUrl(file).value;
    } catch {
      return null;
    }
  },
  null,
);
</script>
