<template>
  <PageViewWrapper>
    <slot name="header" />
    <PageViewContentWrapper
      class="content-wrapper"
      padding="0"
    >
      <ThreadListWrapper
        v-model:active-status="activeStatus"
        :message-count-list="messageCountList"
        @clicked-create-new-thread="$emit('clicked-create-new-thread')"
      >
        <ListInfiniteScroll
          :total="total"
          :height="listHeight"
          :list="list"
          :is-loading="isLoading"
          gap="0"
          @next-page="handleNextPage"
        >
          <template #item="{ item, index }">
            <ThreadPreview
              v-bind="item"
              :with-separator="index !== list.length - 1"
              @click.stop="$emit('clicked-thread-item', { id: item.id })"
            />
          </template>
        </ListInfiniteScroll>
      </ThreadListWrapper>
    </PageViewContentWrapper>
  </PageViewWrapper>
</template>

<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import type { SupplierThreadItem, THREAD_STATUS } from '../../../model/Thread';
import type { MessageCountMap } from '../../../model/MessageCount';
import ThreadListWrapper from '../ThreadListWrapper.vue';
import ThreadPreview from '../ThreadPreview.vue';
import PageViewContentWrapper from '../../decorators/PageViewContentWrapper.vue';
import PageViewWrapper from '../../decorators/PageViewWrapper.vue';
import ListInfiniteScroll from '../../admin/thread-list/ListInfiniteScroll.vue';
import { SUPPLIER_PANEL_EXPANDED_HEIGHT, SUPPLIER_PANEL_HEIGHT } from '../../../model/Constrains';

type Props = {
  total: number;
  isExpanded: boolean;
  isLoading: boolean;
  list: SupplierThreadItem[]
  activeStatus: THREAD_STATUS | null
  messageCountList: MessageCountMap
}
const props = defineProps<Props>();
const $emit = defineEmits<{
  (event: 'clicked-thread-item', payload: Pick<SupplierThreadItem, 'id'>): void
  (event: 'clicked-create-new-thread'): void
  (event: 'update:activeStatus', status: THREAD_STATUS | null): void
  (event: 'next-page', payload: { page: number }): void
}>();
const activeStatus = useVModel(props, 'activeStatus', $emit);

const listHeight = computed(() => {
  const addHeaderAndTabsToHeight = (value: string) => `calc(${value} - 48px - 50px)`;

  return props.isExpanded
    ? addHeaderAndTabsToHeight(SUPPLIER_PANEL_EXPANDED_HEIGHT)
    : addHeaderAndTabsToHeight(SUPPLIER_PANEL_HEIGHT);
});
const handleNextPage = (page: number) => {
  $emit('next-page', { page });
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  overflow: initial;
}
</style>
