export const makeRandomId = () => Math.random().toString(36)
  .substring(7);

const mockThreadItem = () => ({
  id: makeRandomId(),
  title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  status: 'ACTIVE',
  segment: { name: 'Segment wiadomości numer jeden' },
  number: '12/N00/12',
  createdAt: 'Mon Jun 10 2024 12:34:00 GMT+0200 (Central European Summer Time)',
  isNewMessageForAdmin: false,
  isNewMessageForSupplier: false,
});

export const mockSupplierThreadItem = () => ({
  ...mockThreadItem(),
});

export const mockAdminThreadItem = () => ({
  ...mockThreadItem(),
  admin: {
    id: makeRandomId(),
    name: 'Sebastian Kowalski',
  },
  supplier: {
    id: makeRandomId(),
    name: 'Nazwa dostawcy C sp.z.o.o',
    mfgid: 'K0-O1/D',
  },
  hasNote: false,
});

export const mockAdminThreadDetails = () => ({
  id: makeRandomId(),
  title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  segment: { name: 'Segment wiadomości numer jeden' },
  admin: {
    id: makeRandomId(),
    name: 'Grzegorz Brzęczyszczykiewicz',
  },
  supplier: {
    id: makeRandomId(),
    name: 'Nazwa dostawcy C sp.z.o.o',
    mfgid: 'K0-O1/D',
    nip: '7439001289',
  },
  user: {
    name: 'Sebastian Kowalski',
  },
  createdAt: 'Mon Jun 10 2024 12:34:00 GMT+0200 (Central European Summer Time)',
  note: null,
  isFavourite: false,
  status: 'ACTIVE',
  messages: [],
});

export const mockSupplierThreadDetails = () => ({
  id: makeRandomId(),
  admin: {
    id: makeRandomId(),
  },
  supplier: {
    id: makeRandomId(),
  },
  status: 'ACTIVE',
  segment: { name: 'Segment wiadomości numer jeden' },
  number: '12/N00/12',
  isNewMessageForSupplier: false,
  messages: [],
});

export const mockSegmentList = () => [
  'Niestandardowa wiadomość',
  'Tematy związane z produktami',
  'Tematy związane z zamówieniami',
  'Tematy związane z realizacja zamówień lub wysyłką',
  'Tematy związane z fakturami',
  'Tematy związane z reklamacjami',
  'Tematy związane ze sprzedażą i marketingiem',
  'Tematy związane z Panelem Dostawcy',
].map((name, index) => ({ id: String(index), name }));

export const mockMessageCountItem = () => ({
  active: 9, 
  finished: 80, 
  deleted: 10, 
  all: 99,
  isUnread: false,
});

export const mockExampleImage = () => new URL('/histoire/example-image.png', import.meta.url).href;
export const mockExampleCsv = () => new URL('/histoire/example-csv.csv', import.meta.url).href;
export const mockISODate = () => 'Thu Jun 11 2024 12:34:00 GMT+0200 (Central European Summer Time)';

export const mockFileCSV = () => new File([''], 'file', { type: 'text/csv' });
export const mockFileItemCSV = () => ({
  id: makeRandomId(),
  originalName: 'file',
  mimeType: 'text/csv',
});
export const mockFileImage = () => new File([''], 'image', { type: 'image/png' });
export const mockFileItemImage = () => ({
  id: makeRandomId(),
  originalName: 'image',
  mimeType: 'image/png',
});

export const mockMessageItem = () => ({
  id: makeRandomId(),
  user: {
    id: makeRandomId(),
    name: 'Joe Doe',
  },
  createdAt: 'Mon Jun 10 2024 12:34:00 GMT+0200 (Central European Summer Time)',
  content: '',
  files: [],
  isDeleted: false,
  isEdited: false,
});

export const mockAdminSupplierList = () => [
  { id: '1', name: 'Testowy dostawca', mfgid: '123' },
  { id: '2', name: 'Nowy dostawca', mfgid: '456' },
];

export const mockAdminAdminList = () => [
  { id: '1', firstName: 'Testowy', lastName: 'admin' },
  { id: '2', firstName: 'Nowy', lastName: 'admin' },
];
