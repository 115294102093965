import { deleteMessageFromThread, editMessageInThread } from '../../service/common';
import type { DeleteMessageFromThreadPayload, EditMessageInThreadPayload } from '../../model/Payload';
import type { MessageItem } from '../../model/MessageItem';

export const useMessageOptions = () => {
  const deleteMessage = async (options: {
    payload: DeleteMessageFromThreadPayload,
    threadDetails: { messageList: MessageItem[] },
  }) => {
    const messageInThreadDetails = options.threadDetails.messageList.find((message) => message.id === options.payload.id);
    messageInThreadDetails.isSending = (true as MessageItem['isSending']);

    try {
      const { hasError } = await deleteMessageFromThread(options.payload);
      messageInThreadDetails.isSending = (false as MessageItem['isSending']);

      if (hasError) {
        messageInThreadDetails.hasError = (true as MessageItem['hasError']);
        return;
      }

      messageInThreadDetails.isDeleted = (true as MessageItem['isDeleted']);
      messageInThreadDetails.content = ('Wiadomość usunięta' as MessageItem['content']);
    } catch {
      messageInThreadDetails.isSending = (false as MessageItem['isSending']);
      messageInThreadDetails.hasError = (true as MessageItem['hasError']);
    }
  };

  const editMessage = async (options: {
    payload: EditMessageInThreadPayload,
    threadDetails: { messageList: MessageItem[] },
  }) => {
    const messageInThreadDetails = options.threadDetails.messageList.find((message) => message.id === options.payload.id);
    messageInThreadDetails.isSending = (true as MessageItem['isSending']);

    try {
      const { hasError } = await editMessageInThread(options.payload);
      messageInThreadDetails.isSending = (false as MessageItem['isSending']);

      if (hasError) {
        messageInThreadDetails.hasError = (true as MessageItem['hasError']);
        return;
      }

      messageInThreadDetails.isEdited = (true as MessageItem['isEdited']);
      messageInThreadDetails.content = options.payload.content as MessageItem['content'];
    } catch {
      messageInThreadDetails.isSending = (false as MessageItem['isSending']);
      messageInThreadDetails.hasError = (true as MessageItem['hasError']);
    }
  };

  return {
    deleteMessage,
    editMessage,
  };
};
