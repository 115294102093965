<template>
  <div class="message-box-list">
    <div
      v-for="messageItem in messageListWithStatus"
      :key="messageItem.id"
      class="message-box-item"
      data-t="message-box-item"
      :class="{ 'is-active': messageItem.status === MESSAGE_STATUS.ACTIVE }"
    >
      <slot
        name="item"
        v-bind="messageItem"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { getMessageStatus } from '../../mappers/common';
import type { SupplierThreadDetails } from '../../model/Thread';
import type { MessageItemWithStatus } from '../../model/MessageItem';
import type { LoggedUser } from '../../model/LoggedUser';
import { MESSAGE_STATUS } from '../../model/MessageItem';

type Props = Pick<SupplierThreadDetails, 'admin' | 'messageList' | 'supplier'> & {
  loggedUser: LoggedUser
}
const props = defineProps<Props>();

const messageListWithStatus = computed<MessageItemWithStatus[]>(() => {
  if (!props.loggedUser) {
    return [];
  }

  return props.messageList.map((message) => ({
    ...message,
    status: getMessageStatus({
      loggedUser: props.loggedUser,
      user: message.user,
      supplier: props.supplier,
      admin: props.admin,
    }),
  }));
});
</script>

<style lang="scss" scoped>
.message-box-list {
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 12px;
}

.message-box-item {
  max-width: 80%;
  width: max-content;

  &.is-active {
    margin-left: auto;
  }
}
</style>
