import type { LocaleMessages, VueMessageType } from 'vue-i18n';
import { createI18n } from 'vue-i18n';
import pl from './pl.json';
import en from './en.json';

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'pl',
  fallbackLocale: 'pl',
  messages: {
    'pl': pl as unknown as LocaleMessages<VueMessageType>,
    'en': en as unknown as LocaleMessages<VueMessageType>,
  },
});

export default i18n;
