<template>
  <div 
    v-if="!isImpersonating"
    class="start-impersonate-button"
    data-t="start-impersonate-button"
  >
    <GlobalButton
      :loading="isLoadingImpersonate"
      :disabled="!isActiveUser"
      data-t="base-button"
      @click="() => startImpersonate(email)"
    >
      {{ $t('impersonate.start') }}

      <InfoTooltip
        v-if="!isActiveUser"
        data-t="info-tooltip"
      >
        {{ $t('impersonate.notActiveTooltip') }}
      </InfoTooltip>
    </GlobalButton>
  </div>
</template>

<script setup lang="ts">
import InfoTooltip from '@ui/tooltip/InfoTooltip.vue';
import useImpersonateSession from '../composables/useImpersonateSession';

type Props = {
  email: string;
  isActiveUser: boolean;
};
defineProps<Props>();

const {
  startImpersonate,
  isLoadingImpersonate,
  isImpersonating,
} = useImpersonateSession();
</script>

<style lang="scss" scoped>
.start-impersonate-button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
</style>
