<template>
  <div
    class="file-display-new"
    :class="{ 'is-invalid-size': file.isInvalidSize }"
  >
    <FileDisplay
      :file="normalizedFile"
      class="content"
    />
    <GlobalButton 
      data-t="file-display-new-delete"
      outlined
      no-border
      class="button-delete"
      aria-label="Usuń plik"
      @click.stop="handleDelete"
    >
      <GlobalIcon
        name="close"
        class="icon-delete"
      />
    </GlobalButton>
    <span
      v-if="file.isInvalidSize"
      class="error-message"
      data-t="file-display-new-error-message"
    >Plik jest zbyt duży. Maksymalny rozmiar 25 MB</span>
  </div>
</template>

<script setup lang="ts">
import FileDisplay from './FileDisplay.vue';
import { useObjectUrl } from '@vueuse/core';
import { isFileImage } from '../../../helpers';
import type { FileItem } from '../../../model/File';
import type { ValidatedFile } from '../../../model/File';

type Props = {
  file: ValidatedFile;
}
const props = defineProps<Props>();
const $emit = defineEmits<{
  (event: 'delete'): void
}>();

const normalizedFile = computed(() => {
  const type = props.file.type as FileItem['type'];

  return {
    id: Math.random().toString(36)
      .substring(7) as FileItem['id'],
    link: (isFileImage(type) ? useObjectUrl(props.file).value : null) as FileItem['link'],
    name: props.file.name as FileItem['name'],
    type,
  };
});

const handleDelete = () => $emit('delete');
</script>

<style lang="scss" scoped>
.file-display-new {
  display: inline-grid;
  grid-template-areas:
    "content button";
  align-items: center;
  grid-template-columns: 1fr 40px;
  width: auto;
  gap: 8px 16px;
  padding: 8px;
  border-radius: 2px;
  background: $c-tim-blue-light;

  &.is-invalid-size {
    background: $c-tim-red-light;
    grid-template-areas:
      "content button"
      "error-message error-message";

    .button-delete {
      background: $c-tim-red-light;

      &:hover {
        background: $c-white;
      }
    }
  }
}

.error-message {
  grid-area: error-message;
  font-size: 10px;
  line-height: 1;
  font-weight: 600;
  color: $c-tim-red;
}

.content {
  grid-area: content;
}

.icon-delete {
  font-size: 16px;
  padding: 4px;
}

.button-delete {
  grid-area: button;
  width: 40px;
  height: 40px;
  padding: 0;
}
</style>
