import { setIntervalAsync } from '@helpers/functions';

export const usePullingMachine = () => {
  const createPulling = (callback: () => Promise<void>, time: number, isActive: Ref<boolean> = ref(true)) => {
    const pullingRef = shallowRef<() => void>(null);
    
    const clearPulling = () => pullingRef.value?.();
    onBeforeMount(() => {
      clearPulling();
      pullingRef.value = setIntervalAsync(() => {
        if (!isActive.value) {
          return;
        };

        return callback();
      }, time);
    });
    onBeforeUnmount(clearPulling);
  };

  return { createPulling };
};
