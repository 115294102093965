import { toDateFormatWithTime } from '@ui-base';
import type { THREAD_STATUS, SupplierThreadItem, SupplierThreadDetails } from '../model/Thread';
import { isUserActionRequired, mapMessageItem } from './common';
import type { SupplierThreadDetailsDTO } from '../model/SupplierThreadDetailsDTO';
import type { SupplierThreadItemDTO } from '../model/SupplierThreadItemDTO';
import type { SegmentItem } from '../model/Segment';

export const mapSupplierThreadDetails = (unMappedThread: SupplierThreadDetailsDTO): SupplierThreadDetails => {
  return {
    id: unMappedThread.id as SupplierThreadDetails['id'],
    admin: {
      id: (unMappedThread.admin?.id || null) as SupplierThreadDetails['admin']['id'],
    },
    supplier: {
      id: unMappedThread.supplier.id as SupplierThreadDetails['supplier']['id'],
    },
    status: unMappedThread.status as THREAD_STATUS,
    segment: {
      name: unMappedThread.segment.name as SegmentItem['name'],
    },
    number: unMappedThread.number as SupplierThreadDetails['number'],
    requireUserAction: isUserActionRequired({ status: unMappedThread.status, isNewMessage: unMappedThread.isNewMessageForSupplier }),
    messageList: unMappedThread.messages.map(mapMessageItem),
  };
};

export const mapSupplierThreadItem = (unMappedThread: SupplierThreadItemDTO): SupplierThreadItem => {
  return {
    id: unMappedThread.id as SupplierThreadItem['id'],
    title: unMappedThread.title as SupplierThreadItem['title'],
    status: unMappedThread.status as THREAD_STATUS,
    segment: {
      name: unMappedThread.segment.name as SegmentItem['name'],
    },
    number: unMappedThread.number as SupplierThreadItem['number'],
    createdAt: toDateFormatWithTime(unMappedThread.createdAt),
    requireUserAction: isUserActionRequired({ status: unMappedThread.status, isNewMessage: unMappedThread.isNewMessageForSupplier }),
  };
};
