<template>
  <div class="thread-header-details">
    <GlobalButton
      data-t="thread-header-details-button-back"
      class="button-back"
      outlined
      no-border
      @click.stop="handleBack"
    >
      <GlobalIcon
        name="chevron_backward"
        is-symbol
      />
    </GlobalButton>

    <div
      v-if="threadDetails"
      class="content"
    >
      <div class="text-wrapper">
        <RequireUserActionDot
          class="number"
          data-t="thread-header-details-number"
          :require-user-action="threadDetails.requireUserAction"
          :text="threadDetails.number"
        />
        <div
          class="segment"
          :title="threadDetails.segment.name"
          data-t="thread-header-details-segment"
        >
          {{ threadDetails.segment.name }}
        </div>
      </div>

      <ThreadStatus :status="threadDetails.status" />
    </div>
    <GlobalLoader v-else />

    <div class="button-wrapper">
      <VerticalLine :height="MAX_HEIGHT" />
      <GlobalButton
        class="button-close"
        data-t="thread-header-details-button-close"
        as-text
        @click.stop="handleClose"
      >
        <GlobalIcon name="close" />
      </GlobalButton>
    </div>
  </div> 
</template>

<script setup lang="ts">
import type { SupplierThreadDetails } from '../../../model/Thread';
import RequireUserActionDot from '../../common/RequireUserActionDot.vue';
import ThreadStatus from '../../common/ThreadStatus.vue';
import VerticalLine from '../../common/VerticalLine.vue';

type Props = {
  threadDetails: Pick<SupplierThreadDetails, 'number' | 'requireUserAction' | 'segment' | 'status'> | null
}
defineProps<Props>();

const $emit = defineEmits<{
  (event: 'back'): void
  (event: 'close'): void
}>();
const handleBack = () => $emit('back');
const handleClose = () => $emit('close');

const MAX_HEIGHT = `50px`;
</script>

<style lang="scss" scoped>
.thread-header-details {
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $c-white;
}

.button-back {
  width: 40px;
  height: 40px;
  color: $c-tim-blue; 
}

.button-close {
  margin-left: 16px;
  width: 40px;
  height: 40px;
  color: $c-black;

  @include custom-button;
}

.button-wrapper {
  display: flex;
  align-items: center;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 16px;
  height: v-bind(MAX_HEIGHT);
}

.number {
  @include t5;
}

.segment {
  @include one-line-text-with-dots;

  color: $c-tim-gray-dark;
}

.text-wrapper {
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;
  gap: 2px;
}
</style>
