<template>
  <div
    ref="listRef"
    class="list-infinite-scroll"
  >
    <div class="list-infinite-scroll__list">
      <div
        v-if="isLoading"
        class="loader-overflow"
        data-t="list-infinite-scroll-loader-overflow"
      />
      <slot
        v-for="(item, index) in list"
        :key="item.id"
        name="item"
        :item="item"
        :index="index"
      />
    </div>
    <div
      v-if="isLoading"
      class="loader-wrapper"
      data-t="list-infinite-scroll-loader-wrapper"
    >
      <GlobalLoader />
    </div>
  </div>
</template>

<script setup lang="ts" generic="ListItem extends { id: string }">
import { useInfiniteScroll } from '@vueuse/core';
import { PER_PAGE } from '../../../model/Constrains';

type Props = {
  gap?: string,
  total: number,
  height: string,
  list: ListItem[],
  isLoading: boolean,
}
const props = defineProps<Props>();
const $emit = defineEmits<{
  (event: 'next-page', page: number): void,
}>();

const lastPage = computed(() => Math.ceil(props.total / PER_PAGE));
const currentPage = computed(() => Math.ceil(props.list.length / PER_PAGE));

const listRef = useTemplateRef('listRef');
useInfiniteScroll(
  listRef,
  () => {
    // first page is not loaded yet,
    // or it is loading next page,
    // or already loaded all items - it is last page
    if (props.isLoading || props.list.length === 0 || currentPage.value >= lastPage.value) {
      return;
    }

    $emit('next-page', currentPage.value + 1);
  },
  { distance: 10, interval: 1000 },
);

const normalizedGap = computed(() => props.gap || '12px');
</script>

<style lang="scss" scoped>
.list-infinite-scroll {
  height: v-bind('props.height');
  overflow: auto;
  display: flex;
  flex-direction: column;

  &__list {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: v-bind('normalizedGap');
  }
}

.loader-overflow {
  position: absolute;
  inset: 0;
  background-color: rgba($c-tim-gray-lightest, 0.5);
  z-index: 1;
}

.loader-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}
</style>
