<template>
  <Teleport to="body">
    <div
      v-if="isOpen"
      class="file-display-image-full-screen"
      data-t="file-display-image-full-screen"
      tabindex="0"
    >
      <div class="header">
        <div class="text-wrapper">
          <span
            class="top-text"
            :title="topText"
            data-t="file-display-image-full-screen-top-text"
          >{{ topText }}</span>
          <span
            class="bottom-text"
            :title="file.name"
            data-t="file-display-image-full-screen-bottom-text"
          >{{ file.name }}</span>
        </div>

        <UiBaseFileDownload
          :link="file.link"
          method="get"
          :name="file.name"
          :format="file.type"
          title="Pobierz obraz"
        >
          <GlobalIcon
            name="download"
            is-symbol
            tabindex="0"
            class="button"
          />
        </UiBaseFileDownload>
        <GlobalIcon
          title="Zamknij"
          name="close"
          tabindex="0"
          class="button"
          data-t="file-display-image-full-screen-button-close"
          @click.stop="handleClose"
          @keydown.escape="handleClose"
        />
      </div>
      <div class="content">
        <UiBaseFileImage
          class="image"
          data-t="file-display-image-full-screen-image"
          :class="{ 'is-zoomed': isZoomed }"
          :format="file.type"
          method="get"
          :link="file.link"
          :name="file.name"
          @click.stop="handleZoom"
        />
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { onKeyStroke } from '@vueuse/core';
import { useVModel } from '@vueuse/core';
import type { FileItem } from '../../../model/File';
import type { MessageItem } from '../../../model/MessageItem';
import { safeJoin } from '@helpers/functions';
import { UiBaseFileDownload, UiBaseFileImage } from '@ui-base';

interface Props {
  file: FileItem
  userFullName: MessageItem['user']['fullName']
  createdAt: MessageItem['createdAt']
  modelValue: boolean
}
const props = defineProps<Props>();
const $emit = defineEmits<{
  (event: 'update:modelValue', isOpen: boolean): void
}>();

const isOpen = useVModel(props, 'modelValue', $emit);
const handleClose = () => {
  isOpen.value = false;
};

const isZoomed = ref(false);
const handleZoom = () => {
  isZoomed.value = !isZoomed.value;
};

onKeyStroke('Escape', handleClose);

const topText = computed(() => safeJoin([props.userFullName, props.createdAt], ', '));
</script>

<style lang="scss" scoped>
$header-height: 82px;

.file-display-image-full-screen {
  width: 100%;
  height: 100%;
  background-color: rgba($c-tim-gray, 0.82);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 250;
  display: flex;
  flex-direction: column;
}

.content {
  height: calc(100vh - $header-height);
  max-height: calc(100vh - $header-height);
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.header {
  width: 100%;
  height: $header-height;
  display: flex;
  align-items: center;
  padding: 16px;
  background: $c-white;
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  width: 40px;
  height: 40px;

  @include custom-button;
}

.image {
  border-radius: $base-radius;
  max-width: 100%;
  max-height: 100%;
  cursor: zoom-in;

  &.is-zoomed {
    max-width: none;
    max-height: none;
    cursor: zoom-out;
    height: 100%;
  }
}

.text-wrapper {
  display: grid;
  margin-right: auto;
  gap: 2px;
}

.top-text {
  @include one-line-text-with-dots;

  color: $c-tim-gray-dark;
}

.bottom-text {
  @include t7;
  @include one-line-text-with-dots;
}
</style>
