<template>
  <GlobalModal
    v-model="isModalOpen"
    :is-loading="isLoading"
    :save-button-text="confirmText"
    :close-button-text="cancelText"
    @save="$emit('confirm')"
    @close="closeModal"
  >
    <h1 class="confirm-modal">
      <slot name="header" />
    </h1>
    <div class="content">
      <GlobalLoader
        v-if="isLoading"
        class="loader"
      />
      <slot v-else />
    </div>
  </GlobalModal>
</template>

<script lang="ts" setup>
interface Props {
  isLoading?: boolean,
  modelValue: boolean,
  type?: 'confirm' | 'yes-no',
}
const props = defineProps<Props>();
const $emit = defineEmits<{
  (event: 'update:modelValue', value: boolean): void;
  (event: 'confirm'): void;
  (event: 'cancel'): void;
}>();
const isModalOpen: WritableComputedRef<boolean> = computed({
  get(){
    return props.modelValue;
  },
  set(value){
    $emit('update:modelValue', value);
  },
});
const closeModal = () => {
  $emit('cancel');
  isModalOpen.value = false;
};

const confirmText = computed(() => {
  switch(props.type){
    case 'yes-no':
      return 'Tak';
    case 'confirm':
    default:
      return 'Potwierdź';
  }
});

const cancelText = computed(() => {
  switch(props.type){
    case 'yes-no':
      return 'Nie';
    case 'confirm':
    default:
      return 'Anuluj';
  }
});
</script>

<style lang="scss" scoped>

.confirm-modal {
  @include t3;

  margin: 0 0 48px;

  &__actions {
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    @include to-sm {
      width: 100%;
    }
  }

  &__spacer {
    margin-bottom: 16px;
  }
}

.content {
  position: relative;
  min-height: 32px;
}

.loader {
  inset: 0;
  margin: auto;
  position: absolute;
}
</style>
