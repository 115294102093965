import { defineStore } from 'pinia';
import { getUnreadThreadsCount } from '../../service/common';
import { usePullingMachine } from './usePullingMachine';
import { REFRESH_UNREAD_THREADS_COUNT_INTERVAL } from '../../model/Constrains';
import { useLocalStorage } from '@vueuse/core';

export const useUnreadThreads = defineStore('user-common-useUnreadThreads', () => {
  const count = ref(0);

  const updateCount = (newCount: number = 0) => {
    count.value = newCount || 0;
  };

  const fetchCount = async () => {
    const { data } = await getUnreadThreadsCount();
    updateCount(data);
  };

  // should fetch only when token is available
  const isActive = computed(() => !!useLocalStorage('token', undefined).value);

  const { createPulling } = usePullingMachine();
  const startPulling = () => createPulling(
    fetchCount,
    REFRESH_UNREAD_THREADS_COUNT_INTERVAL,
    isActive,
  );

  return {
    count,
    refreshUnreadThreadsCount: fetchCount,
    updateCount,
    startPulling,
  };
});
