import { useUserCommon, useUserRoles, useUserUnathorized } from './composables/useUserStore';
import { useUserPermission } from './composables/useUserPermission';

export const initPackage = () => {
  // TODO: dodaj info do docsów że z racji że store jest singletonem to trzeba go zainicjalizować w momencie ładowania aplikacji
  useUserCommon();
  useUserRoles();
  useUserUnathorized();
  useUserPermission();
};

export {
  useUserCommon,
  useUserRoles,
  useUserUnathorized,
  useUserPermission,
};

export { USER_STATUS, USER_ROLE } from './model';
export type { UserRole } from './model';
