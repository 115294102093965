import { toDateFormatWithTime } from '@ui-base';
import { USER_ROLE } from '@user-common';
import type { SegmentItemDTO } from '../model/SegmentItemDTO';
import type { MessageCountMapDTO } from '../model/MessageCountMapDTO';
import type { ThreadRequireUserAction } from '../model/Thread';
import { THREAD_STATUS } from '../model/Thread';
import type { LoggedUser } from '../model/LoggedUser';
import type { MessageItem } from '../model/MessageItem';
import { MESSAGE_STATUS } from '../model/MessageItem';
import type { MessageCountItem, MessageCountMap } from '../model/MessageCount';
import type { NotStandardSegment, SegmentItem, SegmentList } from '../model/Segment';
import type { FileItem } from '../model/File';
import type { MessageItemDTO } from '../model/MessageItemDTO';

export const isUserActionRequired = (payload: { status: string, isNewMessage: boolean }): ThreadRequireUserAction =>
  !!(payload.isNewMessage && payload.status === THREAD_STATUS.ACTIVE) as ThreadRequireUserAction;

export const getMessageStatus = (payload: {
  loggedUser: LoggedUser
  admin: {
    id: string,
  },
  supplier: {
    id: string,
  },
  user: {
    id: string,
    supplierId?: string
  },
}): MESSAGE_STATUS => {
  // wiadomość zalogowanego użytkownika
  if (payload.user.id === payload.loggedUser.id) {
    return MESSAGE_STATUS.ACTIVE;
  }

  const isSupplier = payload.loggedUser.role === USER_ROLE.ROLE_SUPPLIER;
  const isAdmin = payload.loggedUser.role === USER_ROLE.ROLE_ADMIN;

  if (isAdmin) {
    if (payload.user.supplierId === payload.supplier.id) {
      return MESSAGE_STATUS.ASSIGNED_SUPPLIER; // wiadomość dostawcy
    } else {
      return MESSAGE_STATUS.ADDITIONAL_ADMIN; // wiadomości innego admina
    }
  } else if (isSupplier) {
    if (payload.user.id === payload.admin.id) {
      return MESSAGE_STATUS.ASSIGNED_ADMIN; // wiadomości admina przypisanego do wątku
    } else {
      return MESSAGE_STATUS.ADDITIONAL_ADMIN; // wiadomości innego admina
    }
  }
};

export const mapMessageCountMap = (unMappedMessageCountItem: Omit<MessageCountMapDTO, 'isUnread'>): MessageCountMap => ({
  active: unMappedMessageCountItem.active as MessageCountItem, 
  finished: unMappedMessageCountItem.finished as MessageCountItem, 
  deleted: unMappedMessageCountItem.deleted as MessageCountItem, 
  all: unMappedMessageCountItem.all as MessageCountItem, 
});

export const mapSegmentList = (unMappedSegmentList: SegmentItemDTO[]): SegmentList => {
  if (!unMappedSegmentList.length) {
    return null;
  }

  // NOTE: always first element is not standard one - BE rule
  const [notStandardSegment, ...restSegmentList]: SegmentItem[] = unMappedSegmentList.map(({ id, name }) => ({
    id: id as SegmentItem['id'],
    name: name as SegmentItem['name'],
  }));

  return {
    notStandardSegment: notStandardSegment as NotStandardSegment,
    restSegmentList,
  };
};

export const mapDisplayFile = (unMappedFile: { id: string, originalName: string, mimeType: string }): FileItem => ({
  id: unMappedFile.id as FileItem['id'],
  link: `/message_files/${unMappedFile.id}/file` as FileItem['link'],
  name: unMappedFile.originalName as FileItem['name'],
  type: unMappedFile.mimeType as FileItem['type'],
});

export const mapMessageItem = (unMappedMessage: MessageItemDTO): MessageItem => {
  return {
    id: unMappedMessage.id as MessageItem['id'],
    user: {
      id: unMappedMessage.user.id as MessageItem['user']['id'],
      fullName: unMappedMessage.user.name as MessageItem['user']['fullName'],
      // position: unMappedMessage.user.position as MessageItem['user']['position'],
      // TODO: problem po BE, otrzymuje tylko pozycje dostawcy
      position: undefined as MessageItem['user']['position'],
      supplierId: unMappedMessage.user.supplierId as MessageItem['user']['supplierId'],
    },
    createdAt: toDateFormatWithTime(unMappedMessage.createdAt),
    content: unMappedMessage.content as MessageItem['content'],
    fileList: unMappedMessage.files.map(mapDisplayFile),
    isDeleted: unMappedMessage.isDeleted as MessageItem['isDeleted'],
    isEdited: unMappedMessage.isEdited as MessageItem['isEdited'],
    // NOTE: temporary values changed in component
    isSending: false as MessageItem['isSending'],
    hasError: false as MessageItem['hasError'],
  };
};
