<template>
  <div class="file-list-decorator">
    <slot />
  </div>
</template>

<script setup lang="ts">
type Props = {
  columns?: number
}
const props = withDefaults(defineProps<Props>(), {
  columns: 3,
});

const columns = computed(() => {
  return `1fr `.repeat(props.columns);
});
</script>

<style lang="scss" scoped>
.file-list-decorator {
  display: grid;
  grid-template-columns: v-bind('columns');
  gap: 10px;
  margin-bottom: 10px;
  max-width: 100%;
  max-height: 194px;
  overflow-y: auto;
}
</style>
