import type { Router } from 'vue-router';

const isSentryEnabled = () => !!import.meta.env.VITE_SENTRY_AUTH_TOKEN;

export const addSentry = async (app: App, router: Router) => {
  if (!isSentryEnabled()) {
    return;
  }

  const Sentry = await import('@sentry/vue');
  Sentry.init({
    app,
    release: import.meta.env.VITE_COMMIT_TAG || 'localhost',
    dsn: 'https://30cddbf921d686cabcbe576573047084@sentry.tim.pl/6',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
      Sentry.httpClientIntegration({
        failedRequestStatusCodes: [400, 403, [500, 599]],
      }),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/dostawca\.tim\.pl\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};

export const setUserForSentry = async (payload: Partial<{ id: string, role: string, supplier: { id: string, status: string } }> | null) => {
  if (!isSentryEnabled()) {
    return;
  }

  const Sentry = await import('@sentry/vue');

  if (payload === null) {
    Sentry.setUser(null);
    return;
  }

  Sentry.setUser({
    id: payload?.id,
    role: payload?.role,
    supplierId: payload?.supplier?.id,
    supplierStatus: payload?.supplier?.status,
  });
};

export const captureException = async (error: Error) => {
  console.error(error);

  if (!isSentryEnabled()) {
    return;
  }

  const Sentry = await import('@sentry/vue');
  Sentry.captureException(error);
};
