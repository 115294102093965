import { ROUTER_LINKS } from '@enums/global.enums';
import type { USER_ROLE } from '@user-common';

export const createSupplierDashboardRoute = () =>  ({
  path: ROUTER_LINKS.HOME,
  name: 'AppSupplierDashboard',
  component: () => import('./components/PageView.vue'),
  meta: {
    pageTitle: 'menu.mainPage',
    role: 'Supplier' as USER_ROLE.ROLE_SUPPLIER,
  },
});
