<template>
  <ElementListWrapper class="main-bar__desktop">
    <DesktopElement
      v-for="navigationElement in baseNavigationList"
      :key="navigationElement.text"
      :navigation-element="navigationElement"
    />
    <CommunicationPanelAdminPanel
      v-if="userRoles.isAdmin"
      class="communication-panel"
    />
    <CommunicationPanelSupplierPanel
      v-else
      class="communication-panel"
    />
    <CategoryDesktopElement :navigation-element="myAccountNavigationElement">
      <template #below-list>
        <div class="logout-button">
          <GlobalButton
            full-width
            outlined
            no-border
            @click="userCommon.logout"
          >
            Wyloguj się
          </GlobalButton>
        </div>
      </template>
    </CategoryDesktopElement>
  </ElementListWrapper>
  <GlobalIcon
    :name="isMobileMenuOpen ? 'close' : 'menu'"
    class="main-bar__mobile-icon"
    @click="isMobileMenuOpen = !isMobileMenuOpen"
  />
  <transition name="menu">
    <div
      v-show="isMobileMenuOpen"
      class="main-bar__mobile-nav"
    >
      <ElementListWrapper mobile>
        <MobileElement
          v-for="navigationElement in baseNavigationList"
          :key="navigationElement.text"
          :navigation-element="navigationElement"
        />
        <MobileElement :navigation-element="myAccountNavigationElement" />
        <GlobalButton
          outlined
          no-border
          @click="userCommon.logout"
        >
          Wyloguj się
        </GlobalButton>
      </ElementListWrapper>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { FILE_LINKS, ROUTER_LINKS } from '@enums/global.enums';
import { CommunicationPanelAdminPanel, CommunicationPanelSupplierPanel } from '@communication-panel';
import { useUserCommon, useUserRoles } from '@user-common';
import { ADMIN_ROUTER_LINKS } from '@enums/admin.enums';
import MobileElement from './MobileElement.vue';
import DesktopElement from './DesktopElement.vue';
import ElementListWrapper from './ElementListWrapper.vue';
import type { NavigationElement, CategoryNavigationElement } from '../model';
import CategoryDesktopElement from './CategoryDesktopElement.vue';
import { useFeedback } from '@feedback';

const { t } = useI18n();
const userRoles = useUserRoles();
const isMobileMenuOpen = ref(false);
const $router = useRouter();
$router.afterEach(() => isMobileMenuOpen.value = false);

const baseNavigationList = computed<NavigationElement[]>(() => {
  return [
    {
      text: t('menu.mainPage'),
      to: ROUTER_LINKS.HOME,
    },
    {
      text: t('menu.products'),
      subitems: [
        {
          text: t('menu.productsManagement'),
          to: ROUTER_LINKS.PRODUCTS_MANAGEMENT,
        },
        {
          text: t('menu.productsImport'),
          to: ROUTER_LINKS.PRODUCTS_IMPORT,
        },
        {
          text: t('menu.ceneoValidator'),
          to: ROUTER_LINKS.PRODUCTS_CENEO_VALIDATOR,
        },
      ],
    },
    {
      text: t('menu.sells'),
      subitems: [
        {
          text: t('menu.orders'),
          to: ROUTER_LINKS.SALES_ORDERS,
        },
        {
          text: t('menu.shipments'),
          to: ROUTER_LINKS.SALES_SHIPMENTS,
        },
        {
          text: t('menu.invoices'),
          to: ROUTER_LINKS.SALES_INVOICES,
        },
      ],
    },
    ...userRoles.addItemToArrayAsSupplier({
      text: t('menu.afterSales'),
      subitems: [
        {
          text: t('menu.scoring'),
          to: ROUTER_LINKS.AFTER_SALES_SCORING,
        },
      ],
    }),
    ...userRoles.addItemToArrayAsAdmin({
      text: t('menu.administration'),
      subitems: [
        {
          text: t('menu.registrationRequests'),
          to: ADMIN_ROUTER_LINKS.ADMIN_PANEL_REGISTRATIONS,
        },
        {
          text: t('menu.suppliers'),
          to: ADMIN_ROUTER_LINKS.ADMIN_PANEL_SUPPLIERS,
        },
        {
          text: t('menu.users'),
          to: ADMIN_ROUTER_LINKS.ADMIN_PANEL_USERS,
        },
      ],
    }),
  ];
});

const userCommon = useUserCommon();
const userWelcome = computed(() => {
  if(userCommon.currentUser?.firstName && userCommon.currentUser?.lastName) {
    return `Witaj, ${userCommon.currentUser.firstName} ${userCommon.currentUser.lastName}`;
  }

  return 'Witaj';
});
const { showReportModal } = useFeedback();
const myAccountNavigationElement = computed<CategoryNavigationElement>(() => ({
  text: userWelcome.value,
  subitems: [
    {
      category: 'Ustawienia',
      text: 'Mój profil',
      to: ROUTER_LINKS.SETTINGS_USER,
    },
    ...userRoles.addItemToArrayAsSupplier({
      text: 'Konto dostawcy',
      category: 'Ustawienia',
      to: ROUTER_LINKS.SETTINGS_SUPPLIER_ACCOUNT,
    }),
    {
      text: 'Zgłoś uwagi',
      category: 'Pomoc',
      on: {
        click: showReportModal,
      },
    },
    {
      text: 'Regulamin Panelu Dostawcy',
      category: 'Pozostałe',
      on: {
        click: () => window.open(FILE_LINKS.RULES_PROJECT, '_blank', 'fullscreen=yes'),
      },
    },
    {
      text: 'Polityka prywatności',
      category: 'Pozostałe',
      on: {
        click: () => window.open(FILE_LINKS.RULES_PRIVACY, '_blank', 'fullscreen=yes'),
      },
    },
    {
      text: 'Regulamin OWZ',
      category: 'Pozostałe',
      on: {
        click: () => window.open(FILE_LINKS.RULES_OWZ, '_blank', 'fullscreen=yes'),
      },
    },
  ],
}));
</script>

<style lang="scss" scoped>
.main-bar {
  &__desktop {
    display: flex;

    @include to-xl {
      display: none;
    }
  }

  &__mobile-icon {
    display: none;

    @include to-xl {
      display: block;
      margin-left: auto;
      padding: 0 15px;
      cursor: pointer;
      user-select: none;
    }
  }

  &__mobile-nav {
    position: fixed;
    background-color: $c-white;
    width: 100%;
    z-index: 100;
    top: $s-header;
    right: 0;
    height: 100vh;
    overflow-y: auto;
    transition: all $base-transition;
  }

  .menu-enter-active,
  .menu-leave-active {
    right: -100%;
  }
}

.communication-panel {
  border-left: 1px solid $c-tim-gray-light;
  border-right: 1px solid $c-tim-gray-light;
}

.logout-button {
  padding: 8px;
}
</style>
