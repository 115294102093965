import type { FormatedDateWithTime } from '@ui-base';
import type { FileItem } from './File';

export enum MESSAGE_STATUS {
  ACTIVE = 'ACTIVE',
  ASSIGNED_ADMIN = 'ASSIGNED_ADMIN',
  ASSIGNED_SUPPLIER = 'ASSIGNED_SUPPLIER',
  ADDITIONAL_ADMIN = 'ADDITIONAL_ADMIN',
}

export type MessageItem = {
  id: Distinct<string, 'MessageId'>;
  user: {
    id: Distinct<string, 'MessageUserId'>;
    fullName: Distinct<string, 'MessageUserFullName'>;
    position?: Distinct<string, 'MessageUserPosition'>;
    supplierId?: Distinct<string, 'MessageUserSupplierId'>;
  },
  createdAt: FormatedDateWithTime,
  content: Distinct<string, 'MessageContent'>,
  fileList: FileItem[],
  hasError: Distinct<boolean, 'TemporaryErrorInfoAfterSendFail'>,
  isSending: Distinct<boolean, 'TemporaryLoadingInfoAfterSend'>,
  isDeleted: Distinct<boolean, 'MessageIsDeleted'>,
  isEdited: Distinct<boolean, 'MessageIsEdited'>,
}

export type MessageItemWithStatus = MessageItem & {
  status: MESSAGE_STATUS
}
