
import localMessages from './local-messages';

export const initPackage = () => {
  const { mergeLocaleMessage } = useI18n();
  mergeLocaleMessage('pl', localMessages);
};

export { default as WarehouseAddressNotification } from './components/supplier/WarehouseAddressNotification.vue';
export { default as WarehouseAddressSelectList } from './components/supplier/WarehouseAddressSelectList.vue';
export { default as WarehouseAddressTableListAdmin } from './components/admin/WarehouseAddressTableList.vue';
export { default as WarehouseAddressTableListSupplier } from './components/supplier/WarehouseAddressTableList.vue';
