import type { TableFilterQuery } from '@ui-table';
import {
  SHIPPING_STATUS,
  IMPORT_CONFIGURATION_STATUS,
  IMPORT_PROCESSING_STAGE,
  STATUS_TYPES,
  WIZARD_STEPS,
  CARRIERS,
  IMPORT_FILE_TYPE,
  PRODUCT_IMPORTS_STATUS,
  PACKAGE_TYPES,
  CONFIRMED_STATUS,
  MEASURE_UNIT,
  INVOICES_STATUS,
  CONFIRMATION_FILTER,
} from '@enums/global.enums';
import {
  SUPPLIER_STATUS,
  SUPPLIER_TYPES,
} from '@supplier-common';
import { USER_ROLE, USER_STATUS } from '@user-common';

const NEW_STATUS_COLORS = 'NEW' as SUPPLIER_STATUS.NEW;
const ACTIVE_STATUS_COLORS = 'ACTIVE' as SUPPLIER_STATUS.ACTIVE | USER_STATUS.ACTIVE;
const SENT_STATUS_COLORS = 'SENT' as INVOICES_STATUS.SENT | SHIPPING_STATUS.SENT;
const DELIVERED_STATUS_COLORS = 'DELIVERED' as INVOICES_STATUS.DELIVERED | SHIPPING_STATUS.DELIVERED;
export const STATUS_COLORS = {
  [NEW_STATUS_COLORS]: 'blue',
  [ACTIVE_STATUS_COLORS]: 'green',
  [SENT_STATUS_COLORS]: 'orange',
  [DELIVERED_STATUS_COLORS]: 'green',
  [USER_STATUS.INACTIVE]: 'red',
  [SUPPLIER_STATUS.REGISTERED]: 'orange',
  [SUPPLIER_STATUS.BLOCKED]: 'red',
  [SHIPPING_STATUS.READY_TO_SEND]: 'light-green',
  [SHIPPING_STATUS.MISSED]: 'gray',
  [INVOICES_STATUS.SENDING]: 'orange',
  [INVOICES_STATUS.SENDING_SCHEDULED]: 'orange',
  [INVOICES_STATUS.DRAFT]: 'blue',
  [INVOICES_STATUS.ERROR]: 'red',
} as const;

export const USER_STATUS_TRANSLATIONS = {
  [USER_STATUS.NEW]: 'statuses.new',
  [USER_STATUS.ACTIVE]: 'statuses.active',
  [USER_STATUS.INACTIVE]: 'statuses.inactive',
} as const;

const SUPPLIER_STATUS_TRANSLATIONS = {
  [SUPPLIER_STATUS.NEW]: 'statuses.new',
  [SUPPLIER_STATUS.REGISTERED]: 'statuses.registered',
  [SUPPLIER_STATUS.BLOCKED]: 'statuses.blocked',
  [SUPPLIER_STATUS.ACTIVE]: 'statuses.active',
} as const;

// @TODO: SHIPPING STATUS MOCK
const SHIPPING_STATUS_TRANSLATIONS = {
  [SHIPPING_STATUS.READY_TO_SEND]: 'statuses.ready',
  [SHIPPING_STATUS.SENT]: 'statuses.sent',
  [SHIPPING_STATUS.DELIVERED]: 'statuses.delivered',
  [SHIPPING_STATUS.MISSED]: 'statuses.missed',
} as const;

export const INVOICES_STATUS_TRANSLATIONS = {
  [INVOICES_STATUS.SENDING]: 'statuses.sent',
  [INVOICES_STATUS.SENDING_SCHEDULED]: 'statuses.sent',
  [INVOICES_STATUS.SENT]: 'statuses.sent',
  [INVOICES_STATUS.DELIVERED]: 'statuses.delivered',
  [INVOICES_STATUS.ERROR]: 'statuses.error',
  [INVOICES_STATUS.DRAFT]: 'statuses.draft',
} as const;

export const STATUS_TRANSLATION_MAPS = {
  [STATUS_TYPES.USER]: USER_STATUS_TRANSLATIONS,
  [STATUS_TYPES.SUPPLIER]: SUPPLIER_STATUS_TRANSLATIONS,
  [STATUS_TYPES.SHIPPING]: SHIPPING_STATUS_TRANSLATIONS,
  [STATUS_TYPES.INVOICES]: INVOICES_STATUS_TRANSLATIONS,
} as const;

export const IMPORT_CONFIGURATION_TRANSLATIONS = {
  [IMPORT_CONFIGURATION_STATUS.NEW]: 'importConfigurationStatuses.new',
  [IMPORT_CONFIGURATION_STATUS.SCHEDULED]:
    'importConfigurationStatuses.scheduled',
  [IMPORT_CONFIGURATION_STATUS.PROCESSING]:
    'importConfigurationStatuses.processing',
  [IMPORT_CONFIGURATION_STATUS.PROCESSING_FAILED]:
    'importConfigurationStatuses.processingFailed',
  [IMPORT_CONFIGURATION_STATUS.MAPPING_CATEGORIES]:
    'importConfigurationStatuses.mappingCategories',
  [IMPORT_CONFIGURATION_STATUS.MAPPING_MANUFACTURERS]:
    'importConfigurationStatuses.mappingManufacturers',
  [IMPORT_CONFIGURATION_STATUS.MAPPING_UNITS]:
    'importConfigurationStatuses.mappingUnits',
  [IMPORT_CONFIGURATION_STATUS.MAPPING_FINISHED]:
    'importConfigurationStatuses.mappingFinished',
  [IMPORT_CONFIGURATION_STATUS.CONFIRMED]:
    'importConfigurationStatuses.confirmed',
  [IMPORT_CONFIGURATION_STATUS.ACCEPTED]:
    'importConfigurationStatuses.accepted',
  [IMPORT_CONFIGURATION_STATUS.READY]:
    'importConfigurationStatuses.ready',
  [IMPORT_CONFIGURATION_STATUS.DECLINED]:
    'importConfigurationStatuses.declined',
} as const;

export const PRODUCT_IMPORTS_TRANSLATIONS = {
  [PRODUCT_IMPORTS_STATUS.NEW]: 'Nowy',
  [PRODUCT_IMPORTS_STATUS.WAITING_MAPS]: 'Oczekiwanie: na wygenerowanie map',
  [PRODUCT_IMPORTS_STATUS.SCHEDULED]: 'Przetwarzanie: Zakolejkowany',
  [PRODUCT_IMPORTS_STATUS.PROCESSING]: 'Przetwarzanie',
  [PRODUCT_IMPORTS_STATUS.PROCESSING_FAILED]: 'Bład przetwarzania',
  [PRODUCT_IMPORTS_STATUS.IMPORTING_SCHEDULED]: 'Import zakolejkowany',
  [PRODUCT_IMPORTS_STATUS.IMPORTING_HOLDED]: 'Oczekujący na kolejce',
  [PRODUCT_IMPORTS_STATUS.IMPORTING]: 'Importowaie danych',
  [PRODUCT_IMPORTS_STATUS.IMPORTING_FAILED]: 'Błąd importowania',
  [PRODUCT_IMPORTS_STATUS.FINISHED]: 'Zakończony',
} as const;

export const IMPORT_PROCESSING_STAGE_TRANSLATIONS = {
  [IMPORT_PROCESSING_STAGE.DOWNLOAD]: 'stages.download',
  [IMPORT_PROCESSING_STAGE.EXTRACT]: 'stages.extract',
  [IMPORT_PROCESSING_STAGE.PARSE]: 'stages.parse',
  [IMPORT_PROCESSING_STAGE.VALIDATE]: 'stages.validate',
  [IMPORT_PROCESSING_STAGE.BUILD_MAP]: 'stages.build_map',
} as const;

export const IMPORT_PROCESSING_FAILED_TRANSLATIONS = {
  [IMPORT_PROCESSING_STAGE.DOWNLOAD]: 'processingFailed.download',
  [IMPORT_PROCESSING_STAGE.EXTRACT]: 'processingFailed.extract',
  [IMPORT_PROCESSING_STAGE.PARSE]: 'processingFailed.parse',
  [IMPORT_PROCESSING_STAGE.VALIDATE]: 'processingFailed.validate',
  [IMPORT_PROCESSING_STAGE.BUILD_MAP]: 'processingFailed.build_map',
} as const;

export const SUPPLIER_TYPE_TRANSLATIONS = {
  [SUPPLIER_TYPES.WAREHOUSE_DROPSHIPPING]:
    'supplierTypes.warehouseDropshipping',
  [SUPPLIER_TYPES.WAREHOUSE]: 'supplierTypes.warehouse',
  [SUPPLIER_TYPES.COMPLEMENTARY]: 'supplierTypes.complementary',
  [SUPPLIER_TYPES.COMPLEMENTARY_DROPSHIPPING]:
    'supplierTypes.complementaryDropshipping',
  [SUPPLIER_TYPES.SERVICE]: 'supplierTypes.service',
  [SUPPLIER_TYPES.SERVICE_DROPSHIPPING]: 'supplierTypes.serviceDropshipping',
} as const;

export const WIZARD_STEPS_MAPPED_URLS = {
  [WIZARD_STEPS.CONFIG]: 'konfiguracja',
  [WIZARD_STEPS.VERIFICATION]: 'weryfikacja',
  [WIZARD_STEPS.CATEGORIES_MAP]: 'mapowanie-kategorii',
  [WIZARD_STEPS.UNITS_MAP]: 'mapowanie-jednostek',
  [WIZARD_STEPS.MANUFACTURERS_MAP]: 'mapowanie-producentow',
  [WIZARD_STEPS.SUMMARY]: 'podsumowanie',
} as const;

export const WIZARD_STEPS_MAPPED_STEPS = {
  [WIZARD_STEPS_MAPPED_URLS[WIZARD_STEPS.CONFIG]]: WIZARD_STEPS.CONFIG,
  [WIZARD_STEPS_MAPPED_URLS[WIZARD_STEPS.VERIFICATION]]:
    WIZARD_STEPS.VERIFICATION,
  [WIZARD_STEPS_MAPPED_URLS[WIZARD_STEPS.CATEGORIES_MAP]]:
    WIZARD_STEPS.CATEGORIES_MAP,
  [WIZARD_STEPS_MAPPED_URLS[WIZARD_STEPS.UNITS_MAP]]: WIZARD_STEPS.UNITS_MAP,
  [WIZARD_STEPS_MAPPED_URLS[WIZARD_STEPS.MANUFACTURERS_MAP]]:
    WIZARD_STEPS.MANUFACTURERS_MAP,
  [WIZARD_STEPS_MAPPED_URLS[WIZARD_STEPS.SUMMARY]]: WIZARD_STEPS.SUMMARY,
} as const;

export const CARRIERS_NAMES = {
  [CARRIERS.GLS]: 'GLS',
  [CARRIERS.DHL]: 'DHL',
  [CARRIERS.RABEN]: 'Raben',
  [CARRIERS.DPD]: 'DPD',
  [CARRIERS.INPOST]: 'InPost',
};

export const CARRIER_LOGOS = {
  [CARRIERS.GLS]: 'gls_logo',
  [CARRIERS.DHL]: 'dhl_logo',
  [CARRIERS.RABEN]: 'raben_logo',
  [CARRIERS.DPD]: 'dpd_logo',
  [CARRIERS.INPOST]: 'inpost_logo',
  [CARRIERS.CUSTOM]: 'icon',
};

export const SUMMARY_FILE_TYPES = {
  [IMPORT_FILE_TYPE.CENEO]: 'XML Ceneo',
  [IMPORT_FILE_TYPE.BMECAT]: 'BMECAT',
} as const;

export const PACKAGE_TYPES_TRANSLATIONS = {
  [PACKAGE_TYPES.PARCEL]: 'packageTypes.parcel',
  [PACKAGE_TYPES.PALLET]: 'packageTypes.pallet',
  [PACKAGE_TYPES.PARCEL_LOCKER]: 'packageTypes.parcelLocker',
} as const;

export const CONFIRMED_STATUS_TRANSLATIONS = {
  [CONFIRMED_STATUS.CONFIRMED_NOT_DELAYED_ORDERS_COUNT]: 'confirmed',
  [CONFIRMED_STATUS.UNCONFIRMED_NOT_DELAYED_ORDERS_COUNT]: 'notConfirmed',
  [CONFIRMED_STATUS.UNCONFIRMED_DELAYED_ORDERS_COUNT_ORDERS_COUNT]: 'notConfirmedDelayed',
  [CONFIRMED_STATUS.PARTIAL_CONFIRMED_ORDERS_COUNT]: 'confirmedPartialy',
  [CONFIRMED_STATUS.CONFIRMED_DELAYED_ORDERS_COUNT]: 'confirmedDelayed',
  [CONFIRMED_STATUS.WITHOUT_SHIPMENT_ORDERS_COUNT]: 'missingShipment',
} as const;

export const CONFIRMED_STATUS_FILTERS: { [key in CONFIRMED_STATUS]: TableFilterQuery[] } = {
  [CONFIRMED_STATUS.UNCONFIRMED_NOT_DELAYED_ORDERS_COUNT]: [
    { field: 'confirmed', value: CONFIRMATION_FILTER.UNCONFIRMED, single: true },
    { field: 'delayed', value: false, single: true },
  ],
  [CONFIRMED_STATUS.UNCONFIRMED_DELAYED_ORDERS_COUNT_ORDERS_COUNT]: [
    { field: 'confirmed', value: CONFIRMATION_FILTER.UNCONFIRMED, single: true },
    { field: 'delayed', value: true, single: true },
  ],
  [CONFIRMED_STATUS.WITHOUT_SHIPMENT_ORDERS_COUNT]: [
    { field: 'hasShipments', value: false, single: true },
  ],
  [CONFIRMED_STATUS.PARTIAL_CONFIRMED_ORDERS_COUNT]: [
    { field: 'confirmed', value: CONFIRMATION_FILTER.PARTIAL, single: true },
  ],
  [CONFIRMED_STATUS.CONFIRMED_DELAYED_ORDERS_COUNT]: [
    { field: 'confirmed', value: CONFIRMATION_FILTER.CONFIRMED, single: true },
    { field: 'delayed', value: true, single: true },
  ],
  [CONFIRMED_STATUS.CONFIRMED_NOT_DELAYED_ORDERS_COUNT]: [
    { field: 'confirmed', value: CONFIRMATION_FILTER.CONFIRMED, single: true },
  ],
};

export const MEASURE_UNIT_TRANSLATIONS = {
  [MEASURE_UNIT.KG]: 'measureUnit.kg',
  [MEASURE_UNIT.KM]: 'measureUnit.km',
  [MEASURE_UNIT.PIECE]: 'measureUnit.piece',
  [MEASURE_UNIT.SET]: 'measureUnit.set',
  [MEASURE_UNIT.PACKAGE]: 'measureUnit.package',
  [MEASURE_UNIT.M]: 'measureUnit.m',
  [MEASURE_UNIT.PAIR]: 'measureUnit.pair',
} as const;

export const USER_ROLE_TRANSLATIONS = {
  [USER_ROLE.ROLE_SUPPLIER]: 'supplier',
  [USER_ROLE.ROLE_ADMIN]: 'admin',
};
