import type { AxiosResponse } from 'axios';
import baseService from './baseService';
import type { UserOutput } from '@/types/User.types/be.types';
import { ROUTER_LINKS } from '@enums/global.enums';
import _pick from 'lodash/pick';

interface LoginResponse {
  token: string
  permissions: string[]
  user: UserOutput
  pimcoreRedirectInfo: boolean
}

export const loginUser = async (email: string, password: string): Promise<AxiosResponse<LoginResponse>> => {
  return await baseService.post('/users/login', JSON.stringify({
    email,
    password,
  }));
};

export const sendResetPasswordRequest = async (email: string): Promise<AxiosResponse> => {
  return await baseService.post('/users/forgot-password', JSON.stringify({
    email,
    resetPasswordFormUrlTemplate: `${window.location.origin}${ROUTER_LINKS.SET_PASSWORD}?token={token}`,
  }));
};

export const resetPassword = async (payload: {
  token: string,
  password: string,
  confirm: string,
  regulationsAndPrivacyPolicyAccepted: boolean
}): Promise<AxiosResponse> => {
  const normalizedPayload = JSON.stringify(_pick(payload, ['password', 'confirm', 'regulationsAndPrivacyPolicyAccepted']));
  return await baseService.post(`/users/reset-password/${payload.token}`, normalizedPayload);
};

export const getUserData = async (id: string): Promise<AxiosResponse<UserOutput>> => {
  return await baseService.get(`/users/${id}`);
};

export const reloadUser = async (): Promise<AxiosResponse<{ token: string, user: UserOutput }>> => {
  return await baseService.get('/users/reload');
};
