import type { Placement } from '@popperjs/core';

export enum GLOBAL_STATUS_COLOR {
  BLUE = 'blue',
  GREEN = 'green',
  LIGHT_GREEN = 'light-green',
  RED = 'red',
  ORANGE = 'orange',
  GRAY = 'gray',
  PINK = 'pink',
}

export const enum GLOBAL_NOTIFICATION_TYPES {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INFO = 'INFO',
}
export type DropdownPlacement = Placement
