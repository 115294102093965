<template>
  <div
    ref="container"
    v-click-outside="{ handler: closeOutsideClick, exclude: ['container', 'content'], isClickOnly }"
    class="dropdown-options"
    :class="{ 'is-disabled': disabled }"
  >
    <div
      class="dropdown-options__trigger"
      data-t="global-dropdown-trigger"
      v-on="triggerHandler"
    >
      <slot
        name="trigger"
        :is-open="isOpen"
      />
    </div>
    <Teleport
      v-if="isOpen || popper"
      to="body"
    >
      <div
        v-show="isOpen"
        ref="content"
        class="dropdown-options__content"
        :style="computedStyles"
        v-on="contentHandler"
      >
        <slot
          :is-open="isOpen"
          :close="() => isOpen = false"
        />
      </div>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import type { Modifier, Options } from '@popperjs/core';
import { createPopper } from '@popperjs/core';
import type { DropdownPlacement } from '../model';
import { onKeyStroke } from '@vueuse/core';
import { vClickOutside } from './../outsideClick';

const sameWidthModifier: Modifier<string, Options> = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    state.elements.popper.style.width = `${
      (state.elements.reference as Element).clientWidth
    }px`;
  },
};

type Props = {
  placement?: DropdownPlacement
  width?: string
  containerMinWidth?: boolean
  sameAsContainerWidth?: boolean
  sameWidth?: boolean,
  offset?: number[] | null
  isClickOnly?: boolean,
  // używane kiedy komponent wyżej chce obsłużyć zamykanie i otwieranie dropdowna za pomocą modelu
  preventClick?: boolean,
  modelValue?: boolean,
  disabled?: boolean,
}
const props = withDefaults(defineProps<Props>(), {
  placement: 'bottom-end',
  width: 'auto',
  containerMinWidth: undefined,
  sameWidth: false,
  offset: null,
  isClickOnly: false,
  preventClick: false,
  modelValue: undefined,
  disabled: false,
});
const $emit = defineEmits<{
  (event: 'update:modelValue', value: boolean): void
}>();

const content = useTemplateRef('content');
const container = useTemplateRef('container');
const localIsOpen = ref(false);
const isOpen = computed({
  get() {
    return props.modelValue ?? localIsOpen.value;
  },
  set(value) {
    localIsOpen.value = value;
    if (props.modelValue === undefined) {
      return;
    }
    $emit('update:modelValue', value);
  },
});

const containerWidth = ref('');
const popper = ref<ReturnType<typeof createPopper> | null>(null);
watch(isOpen, async () => {
  if (!isOpen.value) {
    removePopper();
    return;
  }

  containerWidth.value = `${container.value?.clientWidth}px`;

  // wait to render the content before creating the popper
  await nextTick();

  if (!popper.value && container.value && content.value) {
    popper.value = createPopper(container.value, content.value, {
      placement: props.placement,
      modifiers: [
      ...(props.offset ? [{ name: 'offset', options: { offset: props.offset } }] : []),
      ...(props.sameWidth ? [sameWidthModifier] : []),
      ].filter(Boolean),
    });
  }
});

const computedStyles = computed(() => ({
  width: props.width,
  ...(props.containerMinWidth && { minWidth: containerWidth.value }),
  ...(props.sameAsContainerWidth && { width: containerWidth.value }),
}));

const checkIfDisabled = (callback: () => void) => {
  if (props.disabled) {
    isOpen.value = false;
    return;
  }
  callback();
};

// Można otwierać i zamykać klikając w trigger dropdowna w odróżenieniu do contentu
// Zamykanie contentu odbywa się po kliknięciu poza nim lub na żądanie z komponentu wyżej
const triggerHandler = computed(() => {
  if (props.preventClick) {
    return {};
  }

  return props.isClickOnly
    ? { click: () => checkIfDisabled(() => (isOpen.value = !isOpen.value)) }
    : { mouseover: () => checkIfDisabled(() => (isOpen.value = true)) };
});
const contentHandler = computed(() => {
  if (props.preventClick) {
    return {};
  }

  return props.isClickOnly
    ? { click: () => checkIfDisabled(() => (isOpen.value = true)) }
    : { mouseover: () => checkIfDisabled(() => (isOpen.value = true)) };
});

const closeOutsideClick = () => checkIfDisabled(() => (isOpen.value = false));

function removePopper() {
  if(popper.value){
    popper.value.destroy();
    popper.value = null;
  }
}

onUnmounted(removePopper);

onKeyStroke('Escape', () => isOpen.value = false);
</script>

<style lang="scss" scoped>
.dropdown-options {
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;

  &.is-disabled {
    cursor: default;
  }

  &__content {
    z-index: 250;
  }

  &__trigger {
    display: inherit;
    width: 100%;
  }
}
</style>
