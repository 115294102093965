<template>
  <GlobalDropdown
    class="category-desktop-element"
    placement="bottom-start"
  >
    <template #trigger>
      <div class="category-desktop-element__header">
        {{ navigationElement.text }}
        <GlobalIcon name="expand_more" />
      </div>
    </template>
    <template #default="{ close }">
      <GlobalList
        max-width="250px"
        class="category-desktop-element__menu"
      >
        <div
          v-for="(subItems, categoryName) in groupedNavigation"
          :key="categoryName"
          class="category"
        >
          <span class="category__name">
            {{ categoryName }}
          </span>
          <GlobalListElement
            v-for="subItem in subItems"
            :key="subItem.text"
            dense
            :active="route.path === subItem.to"
            class="category__list"
            @click:item="close"
          >
            <GlobalLink
              v-if="subItem.to"
              class="category__list-item"
              :to="subItem.to"
            >
              {{ subItem.text }}
            </GlobalLink>
            <div
              v-else
              class="category__list-item"
              v-on="subItem.on"
            >
              {{ subItem.text }}
            </div>
          </GlobalListElement>
        </div>
        <slot name="below-list" />
      </GlobalList>
    </template>
  </GlobalDropdown>
</template>

<script setup lang="ts">
import type { CategoryNavigationElement } from '../model';
import groupBy from 'lodash/groupBy';

type Props = {
  navigationElement: CategoryNavigationElement
}
const props = defineProps<Props>();

const route = useRoute();

const groupedNavigation = computed(() => groupBy(props.navigationElement.subitems, 'category'));
</script>

<style lang="scss" scoped>
.category-desktop-element {
  height: 100%;

  &__header {
    padding: 0 3px;
    margin: 0 10px;
    white-space: nowrap;
    display: flex;
    position: relative;
    cursor: pointer;
    height: 100%;
    align-items: center;

    @include t7;
  }

  &__menu {
    z-index: 250;
    width: 100%;
    padding: 16px 0 0;
  }
}

.category {
  padding-bottom: 20px;

  &__name {
    @include small;

    padding: 0 0 4px 16px;
    text-transform: uppercase;
    color: $c-tim-gray-dark;
  }

  &__list {
    padding: 0;

    &:last-child {
      border-bottom: 1px solid $c-tim-gray-light;
    }
  }

  &__list-item {
    padding: 14px 16px;
    width: 100%;
    display: flex;

    @include t10;
  }
}
</style>
