import type { AddressBook, EdiConfiguration, ContactBook, Payment, Shipping, Supplier } from '../types/Supplier.types';
import { AddressBookModel, EdiConfigurationModel, PaymentModel, ShippingModel, ContactBookModel, SupplierModel } from '@models/Supplier.models';
import { getSupplier, getSupplierData } from '@services/suppliersService';
import { useRequest } from '@request';

const isEdiLoaded = ref(false);
const ediConfiguration: EdiConfiguration = reactive(EdiConfigurationModel());
export const useEdiConfiguration = (id: string) => {
  const { callRequest } = useRequest();

  const getEdiConfiguration = async () => {
    if (!isEdiLoaded.value) {
      const { data: result, error } = await callRequest<EdiConfiguration>(getSupplierData<EdiConfiguration>(id, 'edi_configuration'));

      if (!error) {
        isEdiLoaded.value = true;
        Object.assign(ediConfiguration, result);
      }
    }
  };

  return {
    getEdiConfiguration,
    ediConfiguration,
  };
};

export const useSupplierDataByIdUser = (id: string) => {
  const { callRequest } = useRequest();
  const { ediConfiguration, getEdiConfiguration } = useEdiConfiguration(id);

  const supplier: Supplier = reactive(SupplierModel());
  const addressData: AddressBook = reactive(AddressBookModel());
  const shipping: Shipping = reactive(ShippingModel());
  const payment: Payment = reactive(PaymentModel());
  const contactBook: ContactBook = reactive(ContactBookModel());

  const getWholeSupplierData = async () => {
    const [ supplier, addressBook, shipping, payment, contactBook ] = await Promise.allSettled([
      callRequest<Supplier>(getSupplier(id)),
      callRequest<AddressBook>(getSupplierData<AddressBook>(id, 'address_book')),
      callRequest<Shipping>(getSupplierData<Shipping>(id, 'shipping')),
      callRequest<Payment>(getSupplierData<Payment>(id, 'payment')),
      callRequest<ContactBook>(getSupplierData<ContactBook>(id, 'contact_book')),
  
      // no value
      getEdiConfiguration(),
    ]);

    return {
      ...supplier.status === 'fulfilled' && { supplier: supplier.value.data },
      ...addressBook.status === 'fulfilled' && { addressBook: addressBook.value.data },
      ...shipping.status === 'fulfilled' && { shipping: shipping.value.data },
      ...payment.status === 'fulfilled' && { payment: payment.value.data },
      edi: ediConfiguration,
      ...contactBook.status === 'fulfilled' && { contactBook: contactBook.value.data },
    };
  };

  return {
    getWholeSupplierData,
    supplier,
    edi: ediConfiguration,
    payment,
    shipping,
    addressData,
    contactBook,
  };
};
