<template>
  <GlobalDropdown
    class="select-element"
    data-t="select-element"
    :class="{ 'is-disabled': isDisabled }"
    :disabled="isDisabled"
    same-as-container-width
    same-width
    is-click-only
  >
    <template #trigger="{ isOpen }">
      <TextInput
        :id="id"
        :name="name"
        class="select-element__input"
        :label="label"
        :rules="rules"
        :model-value="inputValue"
        :is-disabled="isDisabled"
        is-read-only
        :is-dense="isDense"
      >
        <template #after-input>
          <GlobalIcon
            class="input-icon"
            :name="isOpen ? 'arrow_drop_up' : 'arrow_drop_down'"
            data-t="select-element-dropdown-arrow"
          />
        </template>
      </TextInput>
    </template>
    <template #default="{ close }">
      <GlobalList
        max-height="100%"
        data-t="select-element-list"
        :class="{ 'select-element__list--append': $slots['append-list'] }"
      >
        <GlobalListElement
          v-for="item in itemList"
          :key="item.id"
          @click:item="handleItemClick(item),close()"
          @click:icon="handleItemIcon(item),close()"
        >
          {{ item.text }}
        </GlobalListElement>
        <li
          v-if="$slots['append-list']"
          class="select-element__list-append"
        >
          <slot name="append-list" />
        </li>
      </GlobalList>
    </template>
  </GlobalDropdown>
</template>

<script setup lang="ts" generic="LocalItem extends SelectElementItem, LocalModelItem = string | LocalItem">
import type { SelectElementItem } from '../model';
import TextInput from './TextInput.vue';

interface Props {
  id: string
  name: string
  modelValue: LocalModelItem
  itemList: LocalItem[]
  label?: string
  rules?: string
  isDisabled?: boolean
  isDense?: boolean
}
const props = defineProps<Props>();
const $emit = defineEmits<{
  (event: 'update:modelValue', value: LocalModelItem): void
  (event: 'click:icon', value: LocalModelItem): void
}>();

const returnObject = computed(() => typeof props.modelValue === 'object');
const selectedItem = computed(() => {
  return props.itemList.find(item => returnObject.value ? item.text === (props.modelValue as unknown as LocalItem).text : item.id === props.modelValue);
});
const inputValue = computed(() => {
  return selectedItem.value?.text || '';
});
const returnValue = (item: LocalItem): LocalModelItem => (returnObject.value ? item : item.id) as LocalModelItem;
const handleItemClick = (item: LocalItem) => {
  $emit('update:modelValue', returnValue(item));
};
const handleItemIcon = (item: LocalItem) => {
  $emit('click:icon', returnValue(item));
};
</script>

<style lang="scss" scoped>
.select-element {
  position: relative;
  width: 100%;

  &__input {
    width: 100%;
  }

  &.is-disabled {
    .input-icon {
      color: $c-tim-gray;
    }
  }

  &__list--append {
    li:nth-last-child(2) {
      margin-bottom: 48px;
    }
  }

  &__list-append {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 48px;
  }
}

.input-icon {
  color: $c-tim-gray-dark;
}
</style>
