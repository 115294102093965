import type { AxiosError, AxiosResponse, AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';

import { getErrorMessage } from './helpers';
import type { useToast } from '@ui-base';
import type { ErrorInfo } from './model';

export interface CallRequestResult <U, E>{
  data: U,
  error: AxiosError<E> | null,
  hasError: boolean,
  isCancelled: boolean,
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders | null
}

type CallRequest = <U, E>(result: CallRequestResult<U, E> & { method?: string }) => CallRequestResult<U, E>

export const createCallRequest = (
  context: {
    openSuccessToast: ReturnType<typeof useToast>['openSuccessToast'],
    openErrorToast: ReturnType<typeof useToast>['openErrorToast'],
  }) => {
  async function callRequest<T, U = T, E = unknown>(
    request: Promise<AxiosResponse<T>>,
    options: {
      successMessage?: string,
      errorMessage?: string,
      transformData?: (data: T) => U
      errorFallbackData?: (error: AxiosError) => U
      autoSuccessMessage?: boolean,
    } = {},
  ): Promise<CallRequestResult<U, E>> {
    const successToast = createSuccessToast(options.successMessage);
    const errorToast = createErrorToast(options.errorMessage);
    
    try {
      const { data, headers, config = { method: 'get' } } = await request;
    
      const transformedData = (options.transformData ? options.transformData(data as T) : data) as U;
    
      return successToast({
        data: transformedData,
        error: null,
        hasError: false,
        isCancelled: false,
        headers,
        ...(options.autoSuccessMessage || options.autoSuccessMessage === undefined ? { method: config.method } : {}),
      });
    } catch (rawError) {
      const error = (rawError as AxiosError<E>);
      const transformedDataAfterErrorOccurs = (options.errorFallbackData ? options.errorFallbackData(error) : error) as U;
      
      const isCancelled = error.message?.includes('ABORT_TOKEN_ERROR');
      if (isCancelled) {
        return {
          data: transformedDataAfterErrorOccurs,
          error: (error as AxiosError<E>),
          hasError: true,
          isCancelled: true,
          headers: null,
        };
      }

      if (process.env.NODE_ENV !== 'test') {
        console.error(error);
      }
      
      return errorToast({
        data: transformedDataAfterErrorOccurs,
        error: (error as AxiosError<E>),
        hasError: true,
        isCancelled: false,
        headers: null,
      });
    }
  }

  function createSuccessToast (successMessage?: string): CallRequest {
    return (result) => {
      if (result.hasError) {
        return result;
      }

      if (successMessage && typeof successMessage === 'string') {
        context.openSuccessToast?.(successMessage);
      } else if (result.method && ['post', 'put', 'delete', 'patch'].includes(result.method.toLowerCase())) {
        context.openSuccessToast?.('Operacja się powiodła.');
      }

      return result;
    };
  }

  function createErrorToast (errorMessage?: string): CallRequest {
    return (result) => {
      if (!result.hasError) {
        return result;
      }

      const { message: beMessage, skipNotification }  = getErrorMessage(result.error as AxiosError<ErrorInfo>);
  
      // Skip notification when BE wants to (then we will get `skipNotification: true` from BE)
      if (!skipNotification) {
        const message = typeof errorMessage === 'string' ? errorMessage : beMessage;
        context.openErrorToast?.(message);
      }

      return result;
    };
  }

  return callRequest;
};
