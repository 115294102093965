<script lang="ts">
const isLinkExternal = (value: string): boolean => /^(http:|https:|ftp:|mailto:)/.test(value);

export default defineComponent({
  props: {
    to: {
      type: String as PropType<RouteLocationRaw>,
      required: true,
    },
  },

  setup(props, { slots }) {
    const isExternal = typeof props.to === 'string' ? isLinkExternal(props.to) : false;
    const linkTag = isExternal ? 'a' : 'router-link';
    const attr = {
      a: { href: props.to },
      'router-link': { to: props.to },
    };

    return () =>
      h(
        resolveComponent(linkTag),
        {
          ...attr[linkTag],
        },
        () => slots.default?.(),
      );
  },
});
</script>
