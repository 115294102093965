import type { SupplierThreadDetails, SupplierThreadItem } from '../../model/Thread';
import type { MessageCountMap } from '../../model/MessageCount';
import type { SegmentList } from '../../model/Segment';
import { THREAD_STATUS } from '../../model/Thread';
import type { ThreadId } from '../../model/Thread';
import { defineStore } from 'pinia';
import { getThreadDetails, getThreadList } from '../../service/supplier';
import { getSegmentList, getMessageCountList } from '../../service/common';
import { mapMessageCountMap } from '../../mappers/common';

export const useCommonState = defineStore('communication-panel-supplier-useCommonState', () => {
  const isLoading = reactive({
    list: false,
    segmentList: false,
    messageCountList: false,
  });

  const activeStatus = ref<THREAD_STATUS | null>(null);

  const messageCountList = ref<MessageCountMap>(mapMessageCountMap({
    active: 0,
    deleted: 0,
    all: 0,
    finished: 0,
  }));
  const fetchMessageCountList = async () => {
    if (isLoading.messageCountList) {
      return;
    }
    
    isLoading.messageCountList = true;
    const response = await getMessageCountList();
    messageCountList.value = response.messageCountList;
    isLoading.messageCountList = false;
  };

  const threadList = ref<SupplierThreadItem[]>([]);
  const currentPage = ref(1);
  const currentTotal = ref(0);
  const fetchThreadList = async ({ page, action = 'replace' }: { page: number, action?: 'replace' | 'append' }) => {
    if (isLoading.list) {
      return;
    }

    isLoading.list = true;

    const { list, total } = await getThreadList({ page, status: activeStatus.value });
    currentTotal.value = total;
    currentPage.value = page;

    switch (action) {
      case 'replace': {
        threadList.value = list;
        break;
      }
      case 'append': {
        threadList.value = [...threadList.value, ...list];
        break;
      }
    }

    isLoading.list = false;
  };

  const threadDetails = ref<SupplierThreadDetails | null>(null);
  const fetchThreadDetails = async (threadId: ThreadId) => {
    const { data, isCancelled } = await getThreadDetails(threadId);
    if (!isCancelled) {
      threadDetails.value = data;
    }
  };

  const segmentList = ref<SegmentList | null>(null);
  const fetchSegmentList = async () => {
    if (isLoading.segmentList || segmentList.value?.restSegmentList.length) {
      return;
    }
    isLoading.segmentList = true;
    segmentList.value = await getSegmentList();
    isLoading.segmentList = false;
  };

  const refreshMessageCountList = () => fetchMessageCountList();
  const refreshThreadList = async () => {
    if (activeStatus.value !== THREAD_STATUS.ACTIVE) {
      return;
    }

    const pagesArray = Array.from({ length: currentPage.value }, (_, index) => index + 1);

    threadList.value = await Promise.all(
      pagesArray.map((page) => getThreadList({ page, status: activeStatus.value })),
    ).then((res) => res.map((item) => item.list).flat());
  };
  const refreshThreadDetails = () => {
    if (threadDetails.value?.id) {
      return fetchThreadDetails(threadDetails.value.id);
    }
  };

  return {
    activeStatus,
    isLoading,
    currentTotal,
    threadList,
    threadDetails,
    messageCountList,
    segmentList,
    fetchThreadList,
    fetchThreadDetails,
    fetchSegmentList,
    fetchMessageCountList,
    refreshThreadList,
    refreshThreadDetails,
    refreshMessageCountList,
  };
});
