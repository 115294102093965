import baseService from '@services/baseService';
import { useRequest } from '@request';
import type { UserRole } from './model';

export const getUsersRoleList = async (query = ''): Promise<{ data: UserRole[] }> => {
  const { callRequest } = useRequest();

  return await callRequest<{ id: string, name: string }[], UserRole[]>(
    baseService.get(`/user_roles${query}`),
    {
      errorFallbackData: () => [],
    },
  );
};

export const logoutPimcore = async (): Promise<void> => {
  const { callRequest } = useRequest();

  await callRequest(baseService.post(`${import.meta.env.VITE_PIMCORE_URL}/api/md-init-logout`));
};
