import type { AddressBook, Shipping, Payment, EdiConfiguration, ContactBook, Supplier, SupplierContactPerson, SupplierListItem, SupplierListItemOutput } from '@/types/Supplier.types';
import { PAYMENT_DATE } from '@/types/Supplier.types';
import { COUNTRY } from '@enums/global.enums';
import { toDateFormatWithTime } from '@ui-base';

export const SupplierModel = (): Supplier => ({
  type: null,
  id: '',
  mfgid: '',
  taxId: '',
  name: '',
  companySize: '',
  fullName: '',
  mainFirstName: '',
  mainLastName: '',
  mainEmail: '',
  mainPhone: '',
  description: '',
  status: null,
  owzDate: '',
});

export const AddressBookModel = (): AddressBook => ({
  mainAddress: {
    buildingNumber: '',
    street: '',
    city: '',
    postCode: '',
    country: COUNTRY.POLAND,
  },
  complaintAndReturnAddress: {
    buildingNumber: '',
    street: '',
    city: '',
    postCode: '',
    country: COUNTRY.POLAND,
  },
});

export const ShippingModel = (): Shipping => ({
  shippingMethod: '',
  carrier: null,
  customCarrierName: null,
  shippingPriceList: {
    packagePrice: 0,
    packageFreeShippingThreshold: 0,
    palettePrice: 0,
    paletteFreeShippingThreshold: 0,
    overSizePrice: null,
    overSizePriceFreeShippingThreshold: null,
  },
});

export const PaymentModel = (): Payment => ({
  paymentDate: PAYMENT_DATE.DAYS_30,
  accountNumber: '',
});

export const EdiConfigurationModel = (): EdiConfiguration => ({
  enabled: null,
  glnOrIln: '',
});

export const SupplierContactPersonModel = (): SupplierContactPerson => (
  {
    id: '',
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    fullName: '',
  }
);

export const ContactBookModel = (): ContactBook => ({
  sell: SupplierContactPersonModel(),
  offer: SupplierContactPersonModel(),
  documents: SupplierContactPersonModel(),
  complaints: SupplierContactPersonModel(),
  technical: SupplierContactPersonModel(),
  edi: SupplierContactPersonModel(),
  order: SupplierContactPersonModel(),
});

export const suppliersOutputTransformer = (data: SupplierListItemOutput[]): SupplierListItem[] => {
  return data.map((item) => {
    return {
      id: item.id,
      mfgid: item.mfgid,
      name: item.name || '',
      status: item.status,
      taxId: item.taxId,
      type: item.type,
      applicationDate: toDateFormatWithTime(item.applicationDate),
      owzDate: toDateFormatWithTime(item.owzDate),
      source: item.source || '-',
    };
  });
};
