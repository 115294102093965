import baseService, { toFormData } from '@services/baseService';
import { useRequest } from '@request';
import type { SupplierThreadDetails, SupplierThreadItem, THREAD_STATUS, ThreadId } from '../model/Thread';
import { mapSupplierThreadDetails, mapSupplierThreadItem } from '../mappers/supplier';
import type { CreateSupplierMessageInThread, CreateSupplierThread } from '../model/Payload';
import _omit from 'lodash/omit';
import type { SupplierThreadItemDTO } from '../model/SupplierThreadItemDTO';
import type { SupplierThreadDetailsDTO } from '../model/SupplierThreadDetailsDTO';

export const getThreadList = async (payload: { page: number, status: THREAD_STATUS | null,  }): Promise<{ list: SupplierThreadItem[], total: number }> => {
  const { callRequest } = useRequest();

  const normalizedOptions = new URLSearchParams({
    page: String(payload.page),
    ...(payload.status ? { status: payload.status } : {}),
  });
  const url = `/threads?${normalizedOptions}`;

  const { data } =  await callRequest(
    baseService.get<{
      'hydra:member': SupplierThreadItemDTO[],
      'hydra:totalItems': number
    }>(url, { headers: { 'Accept': 'application/ld+json' } }),
    {
      transformData: (list) => ({
        total: list['hydra:totalItems'],
        list: list['hydra:member'].map(mapSupplierThreadItem),
      }),
      errorFallbackData: () => ({ list: [], total: 0 }),
    },
  );

  return data;
};

export const getThreadDetails = async (threadId: ThreadId): Promise<{ data: SupplierThreadDetails, isCancelled: boolean }> => {
  const { callRequest, abortToken } = useRequest();

  const url = `/threads/${threadId}`;
  return callRequest(
    baseService.get<SupplierThreadDetailsDTO>(url, { cancelToken: abortToken(url) }),
    {
      transformData: (details) => mapSupplierThreadDetails(details),
      errorFallbackData: () => null,
    },
  );
};

export const createNewThread = async (payload: CreateSupplierThread): Promise<{ hasError: boolean }> => {
  const { callRequest } = useRequest();

  const formData = toFormData(_omit(payload, ['files']));
  payload.files.forEach((file) => {
    formData.append('files[]', file);
  });

  const { hasError } = await callRequest(
    baseService.post(`/threads`, formData),
    {
      successMessage: 'Wątek został utworzony',
    },
  );

  return { hasError };
};

export const createNewMessageInThread = async (payload: CreateSupplierMessageInThread): Promise<{ hasError: boolean }> => {
  const { callRequest } = useRequest();

  const formData = toFormData(_omit(payload, ['files']));
  payload.files.forEach((file) => {
    formData.append('files[]', file);
  });

  const { hasError } = await callRequest(baseService.post(`/messages`, formData), { autoSuccessMessage: false });

  return { hasError };
};
