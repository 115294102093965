import _throttle from 'lodash/throttle';

interface OutsideClickElement extends HTMLElement {
	outsideEvent: (event: Event) => void
}

type OutsideClickBinding = DirectiveBinding<{
  handler: () => void
  exclude?: string[],
  isClickOnly?: boolean
}>

export const vClickOutside = {
  mounted(el: OutsideClickElement, binding: OutsideClickBinding, vnode: { ctx: { refs: Record<string, HTMLElement> } }) {
    const { handler, exclude, isClickOnly } = binding.value;
    el.outsideEvent = _throttle((event) => {
      let clickedOnExcludedEl = false;

      if (exclude) {
        exclude.forEach(refName => {
          if (!clickedOnExcludedEl) {
            const excludedEl = vnode.ctx.refs[refName];
            clickedOnExcludedEl = excludedEl?.contains(event.target);
          }
        });
      }

      if (!(el === event.target || el.contains(event.target as Node)) && !clickedOnExcludedEl) {
        handler();
      }
    }, 100);
    document.body.addEventListener('click', el.outsideEvent);
    if (!isClickOnly) {
      document.body.addEventListener('mouseover', el.outsideEvent);
    }
    document.body.addEventListener('touchstart', el.outsideEvent, { passive: true });
  },

  unmounted(el: OutsideClickElement) {
    document.body.removeEventListener('click', el.outsideEvent);
    document.body.removeEventListener('mouseover', el.outsideEvent);
    document.body.removeEventListener('touchstart', el.outsideEvent);
  },
};
