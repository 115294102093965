import GlobalModal from './components/GlobalModal.vue';
import GlobalCard from './components/GlobalCard.vue';
import GlobalDropdown from './components/GlobalDropdown.vue';
import GlobalLink from './components/GlobalLink.vue';
import GlobalLoader from './components/GlobalLoader.vue';
import GlobalStatusIndicator from './components/GlobalStatusIndicator.vue';
import GlobalDivider from './components/GlobalDivider.vue';
import GlobalListElement from './components/GlobalListElement.vue';
import GlobalNotification from './components/GlobalNotification.vue';
import GlobalList from './components/GlobalList.vue';
import GlobalButton from './components/GlobalButton.vue';
import GlobalLinkButton from './components/GlobalLinkButton.vue';
import GlobalOptionsButton from './components/GlobalOptionsButton.vue';
import GlobalIcon from './components/GlobalIcon.vue';
import GlobalTooltip from './components/GlobalTooltip.vue';

// ENUMS
export {
  GLOBAL_STATUS_COLOR,
  GLOBAL_NOTIFICATION_TYPES,
} from './model';

export const globalComponents = {
  GlobalModal,
  GlobalButton,
  GlobalCard,
  GlobalDropdown,
  GlobalLink,
  GlobalLinkButton,
  GlobalLoader,
  GlobalStatusIndicator,
  GlobalDivider,
  GlobalListElement,
  GlobalNotification,
  GlobalList,
  GlobalOptionsButton,
  GlobalIcon,
  GlobalTooltip,
};

export const initPackage = (app: App<unknown>) => {
  // global components
  Object.keys(globalComponents).forEach((key) => {
    app.component(key, globalComponents[key as keyof typeof globalComponents]);
  });
};
