import { useToast } from '@ui-base';
import { createCallRequest } from './callRequest';
import { abortToken } from './abortToken';

export const initPackage = () => {};

export function useRequest () {
  const { openSuccessToast, openErrorToast } = useToast();
  const callRequest = createCallRequest({ openSuccessToast, openErrorToast });

  return {
    callRequest,
    abortToken,
  };
}

import { ERROR_AUTH_CODES } from './model';
export { ERROR_AUTH_CODES };
export const JWT_ERROR_CODES = [
  ERROR_AUTH_CODES.EXPIRED_TOKEN,
  ERROR_AUTH_CODES.MISSING_TOKEN,
  ERROR_AUTH_CODES.INVALID_TOKEN,
];
export type JwtErrorCode = typeof JWT_ERROR_CODES[number];
export { type HydraListResponse } from './model';
export { type CallRequestResult } from './callRequest';
