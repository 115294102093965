<template>
  <div class="thread-list-wrapper">
    <div class="tab-list">
      <div
        v-for="({ status, messageCount, text }) in statusList"
        :key="status"
        data-t="thread-list-wrapper-tab-list-item"
        :class="{ 'is-active': activeStatus === status }"
        class="tab-list__item"
        @click.stop="handleStatusChange(status)"
      >
        {{ text }} - {{ messageCount }}
      </div>
    </div>
    <div class="thread-list">
      <slot :status="activeStatus" />
    </div>
    <GlobalButton
      data-t="thread-list-wrapper-new-thread-button"
      class="new-thread-button"
      @click.stop="handleNewThreadClick"
    >
      <GlobalIcon
        class="new-thread-button__icon"
        name="add"
      />
    </GlobalButton>
  </div>
</template>

<script setup lang="ts">
import type { MessageCountMap } from '../../model/MessageCount';
import { THREAD_STATUS } from '../../model/Thread';
import { useVModel } from '@vueuse/core';

type Props = {
  activeStatus: THREAD_STATUS | null
  messageCountList: MessageCountMap
}
const props = defineProps<Props>();
const $emit = defineEmits<{
  (event: 'clicked-create-new-thread'): void
  (event: 'update:activeStatus', status: THREAD_STATUS | null): void
}>();

const handleNewThreadClick = () => {
  $emit('clicked-create-new-thread');
};

const activeStatus = useVModel(props, 'activeStatus', $emit);
const handleStatusChange = (status: null | THREAD_STATUS) => {
  activeStatus.value = status;
};

const statusList = computed(() => [
  { messageCount: props.messageCountList.all, status: null, text: 'Wszystkie' },
  { messageCount: props.messageCountList.active, status: THREAD_STATUS.ACTIVE, text: 'Aktywne' },
  { messageCount: props.messageCountList.finished, status: THREAD_STATUS.FINISHED, text: 'Zakończone' },
  { messageCount: props.messageCountList.deleted, status: THREAD_STATUS.DELETED, text: 'Usunięte' },
]);
</script>

<style lang="scss" scoped>
.thread-list-wrapper {
  display: grid;
  position: relative;
  background: $c-tim-gray-lightest-alt;
  grid-template-rows: max-content;
  height: 100%;
}

.tab-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  padding: 12px 12px 4px;

  &__item {
    @include t9-bold;

    display: inline-flex;
    align-items: center;
    padding: 8px 12px;
    text-transform: uppercase;
    color: $c-tim-gray-dark;
    background: $c-tim-gray-lightest;

    @include custom-button;

    &.is-active {
      border: 1px solid $c-tim-blue;
      color: $c-tim-blue;
    }
  }
}

.new-thread-button {
  position: fixed;
  bottom: 8px;
  right: 8px;
  width: 56px;
  height: 56px;
  z-index: 1;

  &__icon {
    font-size: 32px;
  }
}
</style>
