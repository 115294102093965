export { createCommunicationPanelAdminRoute } from './route';
export { default as CommunicationPanelSupplierPanel } from './components/supplier/SupplierPanel.vue';
export { default as CommunicationPanelAdminPanel } from './components/admin/AdminPanel.vue';

import { useTitle } from '@vueuse/core';
import { useUnreadThreads } from './composables/common/useUnreadThreads';
export { useUnreadThreads };

export const initPackage = () => {
  const unreadThreads = useUnreadThreads();
  unreadThreads.startPulling();

  watch(
    () => unreadThreads.count,
    () => 
    useTitle(
      unreadThreads.count > 0
        ? `(${unreadThreads.count}) Panel Dostawcy TIM.pl`
        : 'Panel Dostawcy TIM.pl',
    ),
  );
};
