import { GLOBAL_NOTIFICATION_TYPES } from '@ui-global';

type ToastNotification = {
  id: string;
  type: GLOBAL_NOTIFICATION_TYPES;
  text: string;
  timeout: NodeJS.Timeout;
}

const toastList = ref<ToastNotification[]>([]);

const useToast = () => {
  const openToast = (type: GLOBAL_NOTIFICATION_TYPES) => (text: string) => {
    if (toastList.value.length >= 5) {
      closeToast(toastList.value[0].id);
    }

    const lastToast = toastList.value[toastList.value.length - 1];
    if (type === lastToast?.type && text === lastToast?.text) {
      return;
    }

    const id = Math.random().toString(36);
    toastList.value.push({
      id,
      type: type,
      text: text,
      timeout: setTimeout(() => {
        closeToast(id);
      }, 10000),
    });
  };

  const openSuccessToast = openToast(GLOBAL_NOTIFICATION_TYPES.SUCCESS);
  const openErrorToast = openToast(GLOBAL_NOTIFICATION_TYPES.ERROR);

  const closeToast = (id: string) => {
    const toast = toastList.value.find((toast) => toast.id === id);

    if (toast?.timeout) {
      clearTimeout(toast.timeout);
    }
    
    toastList.value = toastList.value.filter((toast) => toast.id !== id);
  };

  return {
    openSuccessToast,
    openErrorToast,
    closeToast,
    toastList,
  };
};

export default useToast;
