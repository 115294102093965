<template>
  <PanelTrigger
    :is-active="false"
    :unread-threads-count="unreadThreads.count"
    @click="handleOpenPanel"
  />
</template>

<script setup lang="ts">
import { useUnreadThreads } from '../../composables/common/useUnreadThreads';
import PanelTrigger from '../common/PanelTrigger.vue';
import { ADMIN_ROUTER_LINKS } from '@enums/admin.enums';

const unreadThreads = useUnreadThreads();

const router = useRouter();
const handleOpenPanel = () => router.push(ADMIN_ROUTER_LINKS.ADMIN_PANEL_COMMUNICATION);
</script>
