<template>
  <div
    class="base-file-button"
    :class="{ 'full-width': fullWidth }"
  >
    <GlobalButton
      :full-width="fullWidth"
      :icon="icon"
      type="button"
      outlined
      no-border
      @click="handleFileUpload"
    >
      <input
        ref="fileInput"
        type="file"
        :accept="accept"
        @change="handleFileChange"
      >
      <span class="upload-button__text">
        {{ buttonText }}
      </span>
      <slot />
    </GlobalButton>
    <GlobalButton
      v-if="!clearAfterUpload && hasFile"
      outlined
      no-border
      class="remove-button"
      :title="$t('delete')"
      @click="handleFileRemove"
    >
      <GlobalIcon name="close" />
    </GlobalButton>
  </div>
</template>

<script setup lang="ts">
interface Props {
  accept?: string,
  text: string,
  fullWidth?: boolean
  clearAfterUpload?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  accept: undefined,
  fullWidth: false,
  clearAfterUpload: true,
});
const $emit = defineEmits<{
  (event: 'file-loaded', fileList: FileList): void
}>();

const fileInput = useTemplateRef('fileInput');
const handleFileUpload = () => fileInput.value?.click();

const filesRef = ref<FileList>(null);
const hasFile = computed(() => !!filesRef.value?.length);

const buttonText = computed(() => filesRef.value?.[0].name || props.text);
const icon = computed(() => !hasFile.value && 'file_upload');

const handleFileRemove = () => {
  fileInput.value.value = '';
  filesRef.value = null;
};

const handleFileChange = async (event: Event) => {
  const { files } = event.target as HTMLInputElement;

  filesRef.value = files;

  if (!files.length) {
    return;
  }

  $emit('file-loaded', files);

  if (props.clearAfterUpload) {
    handleFileRemove();
  }
};
</script>

<style lang="scss" scoped>
input {
  display: none;
}

.base-file-button {
  display: flex;

  &.full-width {
    width: 100%;
  }
}

.upload-button__text {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-word;
  word-break: auto-phrase;
}

.remove-button {
  width: 48px;
  margin-left: 8px;
}
</style>
