import baseService from '@services/baseService';
import { useRequest } from '@request';
import type { REGISTRATION_ACTION } from './model';
import { toDateFormatWithTime } from '@ui-base';

export const updateStatus = async (payload: {
  id: string,
  action: REGISTRATION_ACTION,
  // only for decline status
  declineReason?: string,
  successMessage: string
}): Promise<{ data: unknown }> => {
  const { callRequest } = useRequest();

  return await callRequest(
    baseService.post(
      `registration_forms/${payload.id}/${payload.action}`,
      {
        id: payload.id,
        ...(payload.declineReason && { declineReason: payload.declineReason }),
      },
    ),
    { successMessage: payload.successMessage },
  );
};

export const addComment = async (payload: {id: string, comment: string}): Promise<{ data: unknown }> => {
  const { callRequest } = useRequest();

  return await callRequest(
    baseService.post(
      `registration_form_comments`,
      {
        registrationForm: payload.id,
        comment: payload.comment,
      },
    ),
    { successMessage: 'Komentarz został dodany' },
  );
};

export const updateComment = async (payload: {id: string, comment: string}): Promise<{ data: unknown }> => {
  const { callRequest } = useRequest();

  return await callRequest(
    baseService.put(
      `registration_form_comments/${payload.id}`,
      {
        comment: payload.comment,
      },
    ),
    { successMessage: 'Komentarz został edytowany' },
  );
};

export const deleteComment = async (payload: {id: string}): Promise<{ data: unknown }> => {
  const { callRequest } = useRequest();

  return await callRequest(
    baseService.delete(`registration_form_comments/${payload.id}`),
    { successMessage: 'Komentarz został usunięty' },
  );
};

export const getCommentList = async (id: string) => {
  const { callRequest } = useRequest();

  const { data } = await callRequest(
    baseService.get<{
      comment: string,
      updatedAt: string,
      id: string,
      author: string,
      edited: boolean
    }[]>(`registration_forms/${id}/comments?order[updatedAt]=desc`),
    {
      transformData: (list) => list.map(item => ({
        ...item,
        updatedAt: toDateFormatWithTime(item.updatedAt),
      })),
      errorFallbackData: () => [],
    },
  );

  return data;
};
